import { Box, Button, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {SX_STYLES} from '../styles/pages/DashboardStyles'
import { IMAGES } from '../constants/Images';
import LogoPartner from '../components/listItems/LogoPartner';
import ListProduct from '../components/listItems/ListProduct';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import ImageCarousel from '../components/general/ImageCarousel';
import RoundedButton from '../components/cores/RoundedButton';
import { products } from '../helper/products';
import ListHospitals from '../components/listItems/ListHospitals';
import { SPACING } from '../constants/Spacing';
import { ServicesContext } from '../components/provider/ServicesProvider';
import { ROUTES } from '../constants/Routes';
import {services} from '../helper/services'
import { LABELS } from '../constants/Labels';
import { useNavigate } from 'react-router-dom';
import { projects } from '../helper/projects';

const ITEM_WIDTH = 400;

const Dashboard = (props) => {
    const servicesContext = useContext(ServicesContext);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [btnPreviousEnabled, setPreviousEnabled] = useState(true);
    const [btnNextEnabled, setNextEnabled] = useState(false);
    const containerRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
        var filtered = servicesContext.breadCrumbs.filter(function(el) { return el.crumb !== LABELS.PRODUCTS }); 
        servicesContext.setBreadCrumbs(filtered);
    }, []);

    const handleScroll = (scrollAmount) => {
        const newScrollPosition = scrollPosition + scrollAmount;
        setScrollPosition(newScrollPosition);

        if(newScrollPosition === 0){
            setPreviousEnabled(true)
        }else{
            setPreviousEnabled(false)
        }

        var maxScrollLeft = containerRef.current.scrollWidth - containerRef.current.clientWidth;
        
        if(newScrollPosition > maxScrollLeft){
            setNextEnabled(true)
        }else{
            setNextEnabled(false)
        }

        containerRef.current.scrollLeft = newScrollPosition;
    }


    const goToProductDetails = (item) => {
        let productDetails = {
            productItem: item,
        }
        // servicesContext.setBreadCrumbs(prevArray => [...prevArray, {crumb: LABELS.PRODUCTS}])
        servicesContext.setProductData(productDetails);
        navigate(ROUTES.PRODUCT_DETAILS);
    }

    const goToContactUs = () => {
        navigate(ROUTES.ABOUT_US);
        servicesContext.setIsClickedContact(true);
    }

    return (
        <Box>
            <Box sx={SX_STYLES.container}>
                 <Box component='div' sx={[SX_STYLES.imageStyle, { backgroundImage: `url(${IMAGES.SECTION1})` }]}>
                    <Typography sx={SX_STYLES.section}>The ultracare company</Typography>
                    <Box sx={SX_STYLES.companyContainer}>
                    <Typography sx={SX_STYLES.companyName}>HUNDREDFOLDS</Typography>
                    <Typography sx={SX_STYLES.extensionName}>Industries Corporation</Typography>
                    </Box>
                    <Typography sx={SX_STYLES.section1}>Empowering Industry & Healthcare: Your Source for Oxygen, Nitrogen, and More.</Typography>
                    <Button variant="text" sx={SX_STYLES.buttonContact1} onClick={goToContactUs}>Contact Us</Button>
                 </Box>
            </Box>
            <Box sx={SX_STYLES.logoPartnersContainer}>
                <Typography sx={SX_STYLES.section2}>We’ve worked with some great partners</Typography>
                <Box sx={SX_STYLES.logoRowContainer}>
                    <Box sx={SX_STYLES.logoPartnersWrapper}>
                        <LogoPartner
                            image={IMAGES.MEDIMAX}
                            height={'3vw'}
                            width={'15vw'}
                        />
                        <LogoPartner
                            image={IMAGES.COAIRE}
                            height={'3vw'}
                            width={'10vw'}
                        />
                        <LogoPartner
                            image={IMAGES.KYUNGWON}
                            height={'3.5vw'}
                            width={'12vw'}
                        />
                        <LogoPartner
                            image={IMAGES.FST}
                            height={'3vw'}
                            width={'7.5vw'}
                        />
                        <LogoPartner
                            image={IMAGES.NOXERIOR}
                            height={'3vw'}
                            width={'10vw'}
                        />
                    </Box>
                    <Box sx={SX_STYLES.logoPartnersWrapper}>
                        <LogoPartner
                            image={IMAGES.TIGER}
                            height={'3vw'}
                            width={'8vw'}
                        />
                        <LogoPartner
                            image={IMAGES.BEKO}
                            height={'2.6vw'}
                            width={'10vw'}
                        />
                        <LogoPartner
                            image={IMAGES.OMEGA}
                            height={'5vw'}
                            width={'3vw'}
                        />
                    </Box>
                </Box>
            </Box>
            <Box>
            <Box component='div' sx={[SX_STYLES.imageStyle1, { backgroundImage: `url(${IMAGES.SECTION2})`, height: '70.4vh'}]}>
                <Box sx={SX_STYLES.section2Container}>
                    <Typography sx={SX_STYLES.section2Label}>Products</Typography>
                    <Typography sx={SX_STYLES.section2title}>OXYSWING Oxygen Generator</Typography>
                    <Typography sx={SX_STYLES.section2body}>Allows the onsite production of medical oxygen. The more you<br/>use it, the more you save it.</Typography>
                    <Box sx={SX_STYLES.btnReadContainer}>
                        <RoundedButton variant='contained' text='Read Details' onClick={()=>goToProductDetails(products[0])}/>
                    </Box>
                </Box>
            </Box>
            </Box>
            <Box sx={SX_STYLES.productsOfferedContainer}>
                <Box sx={SX_STYLES.productsOfferedWrapper}>
                    <Box sx={SX_STYLES.servicesSectionContainer}>
                        <Typography sx={SX_STYLES.sectionLabel}>Other Products Offered</Typography>
                        <Typography sx={SX_STYLES.sectionSubLabel}>Hear from some of our amazing customers who are automating their finances.</Typography>
                    </Box>
                    <RoundedButton variant='text' text='View all products' onClick={() => navigate(ROUTES.PRODUCTS)}/>
                </Box>
            </Box>
            <Box ref={containerRef} sx={SX_STYLES.listProductsContainer}>
                {
                    products.map((item, index) =>
                        <Box key={index}>
                            <ListProduct image={item.image} title={item.title} body={item.body} index={index} arrayLength={products.length} onClick={()=>goToProductDetails(item)} medium/>
                        </Box>
                    )
                }
            </Box>
            <Box sx={SX_STYLES.paginationContainer}>
                <Button variant='outlined' sx={SX_STYLES.pagination} disabled={btnPreviousEnabled} onClick={()=>(handleScroll(-ITEM_WIDTH))}>
                    <ArrowBack sx={SX_STYLES.paginationArrow} />
                </Button>
                <Button variant='outlined' sx={SX_STYLES.pagination} disabled={btnNextEnabled} onClick={()=>(handleScroll(ITEM_WIDTH))}>
                    <ArrowForward sx={SX_STYLES.paginationArrow} />
                </Button>
            </Box>
            <Box sx={SX_STYLES.servicesContainer}>
                <Typography sx={SX_STYLES.section2Label}>Services</Typography>
                <Box sx={SX_STYLES.productsOfferedWrapper}>
                    <Box sx={SX_STYLES.servicesSectionContainer}>
                        <Typography sx={SX_STYLES.sectionLabel}>Our Offered Services</Typography>
                        <Typography sx={SX_STYLES.sectionSubLabel}>Untitled are a commercial interior design studio. We specialise in customised office design,<br/>restaurant design, shop design, and studio design.</Typography>
                    </Box>
                    <RoundedButton variant='text' text='See all services' onClick={() =>  navigate(ROUTES.SERVICES)}/>
                </Box>
            </Box>
            <ImageCarousel data={services.slice(0, 4)} productDetails={(e)=>goToProductDetails(e)}/>
            <Box sx={[SX_STYLES.servicesContainer, {paddingBottom: SPACING._96px}]}>
                <Box sx={[SX_STYLES.productsOfferedWrapper, {alignItems: 'flex-start'}]}>
                    <Box sx={[SX_STYLES.servicesSectionContainer, {marginRight: SPACING._64px}]}>
                        <Typography sx={SX_STYLES.sectionLabel}>Recently Installed</Typography>
                        <Typography sx={SX_STYLES.sectionSubLabel}>List of hospitals in the Philippines with HIC Oxygen Generator.</Typography>
                        <Box sx={{marginTop: '3vh'}}>
                            <RoundedButton variant='text' text='View all projects' onClick={() =>  navigate(ROUTES.INSTALLED_PROJECTS)}/>
                        </Box>
                    </Box>
                    <Box sx={SX_STYLES.hospitalsContainer}>
                        {projects.slice(0, 3).map((item, index) => 
                            <Box key={index}>
                                <ListHospitals image={item.image} date={item.date} hospital={item.title} description={item.body} />
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
            <Box sx={SX_STYLES.awardContainer}>
                <Typography sx={SX_STYLES.award}>Best Performing Distributor Award</Typography>
                <Typography sx={SX_STYLES.awardDescription}>The Oxyswing line of PSA Oxygen generators provide a reliable and inexpensive<br/>solution for a number of different applications.</Typography>
                <Box component='img' src={IMAGES.AWARD} width={'62.5vh'}/>
            </Box>
        </Box>
    );
}

export default Dashboard;