import { SIZES } from '../../../constants/Sizes';
import { COLORS } from '../../../constants/Colors';
import { FONTS } from '../../../constants/Fonts';

export const SX_STYLES = {
    inputStyle: {
        width: '38vh',
        '& fieldset': {
            borderRadius: '1vh',
            border: `1px solid ${COLORS.grayDD}`,
        },
    },
    inputPropsStyle: {
        style: {
          ...FONTS.font400,
          fontSize: SIZES._16px,
          color: COLORS.gray85,
          height: "4.8vh",
          padding: "0 2vh",
        },
    }
}