import React from 'react';
import NavigationBar from '../components/header/NavigationBar';
import { Box } from '@mui/material';
import { SX_STYLES } from '../styles/pages/LandingPageStyles';
import Dashboard from './Dashboard';
import ProductDetails from './products/ProductDetails';
import { ROUTES } from '../constants/Routes';
import Products from './products/Products';
import Services from './services/Services';
import InstalledProjects from './projects/InstalledProjects';
import AboutUs from './AboutUs';
import { Routes, Route } from "react-router-dom";
import FooterSocialLinks from '../components/footer/FooterSocialLinks';

const LandingPage = (props) => {
    return (
        <Box sx={SX_STYLES.contentContainer}>
            <NavigationBar/>
            <Routes>
                <Route path='/' element={<Dashboard/>}/>
                <Route path={ROUTES.PRODUCTS} element={<Products/>}/>
                <Route path={ROUTES.PRODUCT_DETAILS} element={<ProductDetails/>}/>
                <Route path={ROUTES.PRODUCTS+'/'+ROUTES.PRODUCT_DETAILS} element={<ProductDetails/>}/>
                <Route path={ROUTES.SERVICES} element={<Services/>}/>
                <Route path={ROUTES.SERVICES+'/'+ROUTES.PRODUCT_DETAILS} element={<ProductDetails/>}/>
                <Route path={ROUTES.ABOUT_US} element={<AboutUs/>}/>
                <Route path={ROUTES.INSTALLED_PROJECTS} element={<InstalledProjects/>}/>
                <Route path={ROUTES.INSTALLED_PROJECTS+'/'+ROUTES.PRODUCT_DETAILS} element={<ProductDetails/>}/>
            </Routes>
            <FooterSocialLinks/>
        </Box>
    );
}

export default LandingPage;