import { Box } from '@mui/material';
import React from 'react';

const LogoPartner = (props) => {
    return (
        <Box
            component="img"
            src={props.image}
            height={props.height}
            width={props.width}
        />
    );
}

export default LogoPartner;