import { COLORS } from "../../../constants/Colors"
import { FONTS } from "../../../constants/Fonts"
import { SIZES } from "../../../constants/Sizes"

export const SX_STYLES = {
    selectContainer: {
        display: "flex",
        alignItems: "center",
    },
    selectStyle: {
        ...FONTS.font400,
        fontSize: SIZES._16px,
        background: COLORS.white,
        height: "4.8vh",
        width: '38vh',
        '& fieldset': {
            borderRadius: '1vh',
            border: `1px solid ${COLORS.grayDD}`,
        },
    },
    icon: {
        transform: 'rotate(90deg)', 
        marginRight: '1.5vh', 
        color: COLORS.gray85
    }
}