export const link1 = [
    {
        id: 1,
        link: 'Company'
    },
    {
        id: 2,
        link: 'About Us'
    },
    {
        id: 3,
        link: 'Products'
    },
    {
        id: 4,
        link: 'Services'
    },
    {
        id: 5,
        link: 'Projects'
    },
    {
        id: 6,
        link: 'Contact'
    }
]

export const link2 = [
    {
        id: 1,
        link: 'Social'
    },
    {
        id: 2,
        link: 'LinkedIn',
        url: 'https://www.linkedin.com/company/hundredfolds-industries-corporation/'
    },
    {
        id: 3,
        link: 'Facebook',
        url: 'https://www.facebook.com/1624nico'
    },
]

export const link3 = [
    {
        id: 1,
        link: 'Legal'
    },
    {
        id: 2,
        link: 'Terms'
    },
    {
        id: 3,
        link: 'Privacy'
    },
    {
        id: 4,
        link: 'Cookies'
    },
    {
        id: 5,
        link: 'Licenses'
    },
]