import { ServicesProvider } from "./components/provider/ServicesProvider";
import MainStack from "./components/routes/MainStack";

function App() {
  return (
    <ServicesProvider>
      <MainStack/>
    </ServicesProvider>
  );
}

export default App;
