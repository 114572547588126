import { Box, Typography } from '@mui/material';
import React from 'react';
import { SX_STYLES } from '../../styles/components/listItems/ListItemImageStyles';
import RoundedButton from '../cores/RoundedButton';

const ListItemImage = (props) => {
    const sectionMargin = {
        right: props.itemID === 2 ? '10%' : '58.5%'
    }
    const imageCover = {
        backgroundImage: `url(${props.image})`,
        backgroundSize: props.imageHeight,
    }
    return (
        <Box>
            <Box component='div' sx={[SX_STYLES.imageStyle, imageCover, {borderRadius: props.noRadius ? 0 : '4vh', height: props.height}]} />
            {
                props.itemID <= 2 ?  
                    <Box sx={[SX_STYLES.sectionContainer, sectionMargin]}>
                        <Box sx={SX_STYLES.sectionWrapper}>
                            <Typography sx={SX_STYLES.sectionLabel}>{props.category}</Typography>
                            <Typography sx={SX_STYLES.sectionTitle}>{props.title}</Typography>
                            <Typography sx={SX_STYLES.sectionBody}>{props.body}</Typography>
                        </Box>
                        {
                            !props.noRadius && 
                                <Box sx={SX_STYLES.btnReadContainer}>
                                    <RoundedButton variant='contained' text='See details' onClick={props.onClick}/>
                                </Box>
                        }
                        
                    </Box>
                :   
                    <Box sx={SX_STYLES.sectionContainer1}>
                        <Box sx={[SX_STYLES.sectionWrapper, {alignItems: 'center', }]}>
                            <Typography sx={SX_STYLES.sectionLabel}>{props.category}</Typography>
                            <Typography sx={SX_STYLES.sectionTitle}>{props.title}</Typography>
                            <Typography sx={[SX_STYLES.sectionBody, {textAlign: 'center'}]}>{props.body}</Typography>
                        </Box>
                        {
                            !props.noRadius &&
                                <Box sx={SX_STYLES.btnReadContainer}>
                                    <RoundedButton variant='contained' text='See details' onClick={props.onClick}/>
                                </Box>
                        }     
                    </Box>
            }
           
        </Box>
    );
}

export default ListItemImage;