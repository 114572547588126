import { IMAGES } from "../constants/Images";

export const projects = [
    {
        id: 1,
        image: IMAGES.PROJECT1,
        title: 'Camp Catitipan Hospital',
        body: 'A recent 2023 project. HIC has an ongoing project for Oxygen Generator Modular Type installation at Camp Catitipan Hospital new Oxygen Plant.',
        date: 'Year 2023',
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT1
            },
            {
                id: 2,
                image: IMAGES.PROJECT11
            },
            {
                id: 3,
                image: IMAGES.PROJECT12
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'A recent 2023 project. HIC has an ongoing project for Oxygen Generator Modular Type installation at Camp Catitipan Hospital new Oxygen Plant.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 2,
        image: IMAGES.PROJECT2,
        title: 'Lanao Del Sur Provincial Hospital',
        body: 'A recent 2023 project. HIC has an on-going project for Oxygen Generator Modular Type installation at Lanao Del Sur Provincial Hospital (LDSPH) Marawi new Oxygen Plant.',
        date: 'Year 2023',
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT2
            },
            {
                id: 2,
                image: IMAGES.PROJECT21
            },
            {
                id: 3,
                image: IMAGES.PROJECT22
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'A recent 2023 project. HIC has an on-going project for Oxygen Generator Modular Type installation at Lanao Del Sur Provincial Hospital (LDSPH) Marawi new Oxygen Plant.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 3,
        image: IMAGES.PROJECT3,
        title: 'SPMC New Oxygent Plant',
        body: 'HIC has a new on-going project for four (4) units of Oxygen Generator Modular Type with Refilling Station, two (2) Units of Medical Air System and Mecial Vacuum System at Southern Philippines Medical Center (SPMC).',
        date: 'Year 2023',
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT3
            },
            {
                id: 2,
                image: IMAGES.PROJECT31
            },
            {
                id: 3,
                image: IMAGES.PROJECT32
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'HIC has a new on-going project for four (4) units of Oxygen Generator Modular Type with Refilling Station, two (2) Units of Medical Air System and Mecial Vacuum System at Southern Philippines Medical Center (SPMC).',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 4,
        image: IMAGES.PROJECT4,
        title: 'Davao Medical School Foundation Inc',
        body: 'Year 2023 HIC has installed two (2) units of Oxygen Generator Modular Type at Davao Medical School Foundation Inc (DMSF).',
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT4
            },
            {
                id: 2,
                image: IMAGES.PROJECT41
            },
            {
                id: 3,
                image: IMAGES.PROJECT42
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Year 2023 HIC has installed two (2) units of Oxygen Generator Modular Type at Davao Medical School Foundation Inc (DMSF).',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 5,
        image: IMAGES.PROJECT5,
        title: 'Premier Medical Center',
        body: 'HIC team has installed two (2) units of Oxygen Generator Modular Type at Premier Medical Center, Zamboanga City year 2022.',
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT5
            },
            {
                id: 2,
                image: IMAGES.PROJECT51
            },
            {
                id: 3,
                image: IMAGES.PROJECT52
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'HIC team has installed two (2) units of Oxygen Generator Modular Type at Premier Medical Center, Zamboanga City year 2022.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 6,
        image: IMAGES.PROJECT6,
        title: 'Lanang Premiere Doctors Hospital, Inc',
        body: 'Hundredfolds Industries Corp. installed two (2) units of OS-48 Oxygen Generator Modular Type at Lanang Premiere Doctors Hospital, Inc year 2019.',
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT6
            },
            {
                id: 2,
                image: IMAGES.PROJECT61
            },
            {
                id: 3,
                image: IMAGES.PROJECT62
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Hundredfolds Industries Corp. installed two (2) units of OS-48 Oxygen Generator Modular Type at Lanang Premiere Doctors Hospital, Inc year 2019.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 7,
        image: IMAGES.PROJECT7,
        title: 'Zamboanga City Medical Center',
        body: 'Zamboanga City Medical Center was installed with two (2) units of OS-48, six (6) units of EOS-40 Oxygen Modular Type with Refilling Station during year 2018.',
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT7
            },
            {
                id: 2,
                image: IMAGES.PROJECT71
            },
            {
                id: 3,
                image: IMAGES.PROJECT72
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Zamboanga City Medical Center was installed with two (2) units of OS-48, six (6) units of EOS-40 Oxygen Modular Type with Refilling Station during year 2018.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 8,
        image: IMAGES.PROJECT8,
        title: 'Laoag City General Hospital',
        body: 'Laoag City General Hospital of Ilocos Norte was installed with two (2) units of Oxygen Generator Modular type year 2018 by HIC.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT8
            },
            {
                id: 2,
                image: IMAGES.PROJECT81
            },
            {
                id: 3,
                image: IMAGES.PROJECT82
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Laoag City General Hospital of Ilocos Norte was installed with two (2) units of Oxygen Generator Modular type year 2018 by HIC.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 9,
        image: IMAGES.PROJECT9,
        title: 'Ilocos Training & Regional Medical Center',
        body: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type at Ilocos Training & Regional Medical Center, Parian City of San Fernando, La Union year 2018.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT9
            },
            {
                id: 2,
                image: IMAGES.PROJECT91
            },
            {
                id: 3,
                image: IMAGES.PROJECT92
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type at Ilocos Training & Regional Medical Center, Parian City of San Fernando, La Union year 2018.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 10,
        image: IMAGES.PROJECT10,
        title: 'Dr. Jose Rizal Memorial Hospital',
        body: 'The HIC team installed two units of Oxygen Generator Modular Type at Dr. Jose Rizal Memorial Hospital in Lawaan, Dapitan City in 2018.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT10
            },
            {
                id: 2,
                image: IMAGES.PROJECT101
            },
            {
                id: 3,
                image: IMAGES.PROJECT102
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'The HIC team installed two units of Oxygen Generator Modular Type at Dr. Jose Rizal Memorial Hospital in Lawaan, Dapitan City in 2018.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 11,
        image: IMAGES.PROJECT011,
        title: 'Governor Celestino Gallares Memorial Hospital',
        body: 'The HIC team installed two (2) units of Oxygen Generator Modular Type at Governor Celestino Gallares Memorial Hospital in 0053 Miguel Parras Street, Tagbilaran City, Bohol in 2018.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT011
            },
            {
                id: 2,
                image: IMAGES.PROJECT0111
            },
            {
                id: 3,
                image: IMAGES.PROJECT0112
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'The HIC team installed two (2) units of Oxygen Generator Modular Type at Governor Celestino Gallares Memorial Hospital in 0053 Miguel Parras Street, Tagbilaran City, Bohol in 2018.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 12,
        image: IMAGES.PROJECT012,
        title: 'Southern Philippines Medical Center (SPMC)',
        body: 'The Hundredfolds Industries Corp. team installed four (4) units of Oxygen Generator Modular Type with Refilling Station, two (2) units of Medical Air System and Medical Vacuum System at Southern Philippines Medical Center (SPMC) in J.P. Laurel Avenue, Davao City in 2016.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT012
            },
            {
                id: 2,
                image: IMAGES.PROJECT0121
            },
            {
                id: 3,
                image: IMAGES.PROJECT0122
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'The Hundredfolds Industries Corp. team installed four (4) units of Oxygen Generator Modular Type with Refilling Station, two (2) units of Medical Air System and Medical Vacuum System at Southern Philippines Medical Center (SPMC) in J.P. Laurel Avenue, Davao City in 2016.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 13,
        image: IMAGES.PROJECT013,
        title: 'Vicente Sotto Memorial Medical Center',
        body: 'The Hundredfolds Industries Corp. team installed four (4) units of Oxygen Generator Modular Type with a Refilling Station, along with two (2) units each of Medical Air System and Medical Vacuum System at Vicente Sotto Memorial Medical Center in Cebu City in 2016.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT013
            },
            {
                id: 2,
                image: IMAGES.PROJECT0131
            },
            {
                id: 3,
                image: IMAGES.PROJECT0132
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'The Hundredfolds Industries Corp. team installed four (4) units of Oxygen Generator Modular Type with a Refilling Station, along with two (2) units each of Medical Air System and Medical Vacuum System at Vicente Sotto Memorial Medical Center in Cebu City in 2016.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 14,
        image: IMAGES.PROJECT014,
        title: 'Northern Mindanao Medical Center',
        body: 'Our team installed two (2) units of Oxygen Generator Modular Type at Northern Mindanao Medical Center in Misamis Oriental in 2016.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT014
            },
            {
                id: 2,
                image: IMAGES.PROJECT0141
            },
            {
                id: 3,
                image: IMAGES.PROJECT0142
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Our team installed two (2) units of Oxygen Generator Modular Type at Northern Mindanao Medical Center in Misamis Oriental in 2016.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 15,
        image: IMAGES.PROJECT015,
        title: 'Jose B. Lingad Memorial Regional Hospital',
        body: 'Our team installed two (2) units of Oxygen Generator Modular Type at Jose B. Lingad Memorial Regional Hospital in San Fernando Pampanga in 2016.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT015
            },
            {
                id: 2,
                image: IMAGES.PROJECT0151
            },
            {
                id: 3,
                image: IMAGES.PROJECT0152
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Our team installed two (2) units of Oxygen Generator Modular Type at Jose B. Lingad Memorial Regional Hospital in San Fernando Pampanga in 2016.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 16,
        image: IMAGES.PROJECT016,
        title: 'Philippines Children’s Medical Center',
        body: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type at Philippines Children’s Medical Center in Quezon City in 2016.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT016
            },
            {
                id: 2,
                image: IMAGES.PROJECT0161
            },
            {
                id: 3,
                image: IMAGES.PROJECT0162
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type at Philippines Children’s Medical Center in Quezon City in 2016.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 17,
        image: IMAGES.PROJECT017,
        title: 'Amai Pakpak Medical Center',
        body: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type at Amai Pakpak Medical Center in Marawi City in 2016.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT017
            },
            {
                id: 2,
                image: IMAGES.PROJECT0171
            },
            {
                id: 3,
                image: IMAGES.PROJECT0172
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type at Amai Pakpak Medical Center in Marawi City in 2016.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 18,
        image: IMAGES.PROJECT018,
        title: 'Western Visayas State University Medical Center',
        body: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type at Western Visayas State University Medical Center in Marawi City in 2016.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT018
            },
            {
                id: 2,
                image: IMAGES.PROJECT0181
            },
            {
                id: 3,
                image: IMAGES.PROJECT0182
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type at Western Visayas State University Medical Center in Marawi City in 2016.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 19,
        image: IMAGES.PROJECT019,
        title: 'Adela Serra Ty Memorial Medical Center',
        body: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type at Adela Serra Ty Memorial Medical Center in Surigao Del Sur in 2016.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT019
            },
            {
                id: 2,
                image: IMAGES.PROJECT0191
            },
            {
                id: 3,
                image: IMAGES.PROJECT0192
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type at Adela Serra Ty Memorial Medical Center in Surigao Del Sur in 2016.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 20,
        image: IMAGES.PROJECT036,
        title: 'Caraga Regional Hospital',
        body: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type at Caraga Regional Hospital in Surigao City in 2016.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT036
            },
            {
                id: 2,
                image: IMAGES.PROJECT0361
            },
            {
                id: 3,
                image: IMAGES.PROJECT0362
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type at Caraga Regional Hospital in Surigao City in 2016.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 21,
        image: IMAGES.PROJECT020,
        title: 'Eastern Visayas Regional Medical Center - Mother & Child Hospital',
        body: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type at Eastern Visayas Regional Medical Center - Mother & Child Hospital in Tacloban City in 2016.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT020
            },
            {
                id: 2,
                image: IMAGES.PROJECT0201
            },
            {
                id: 3,
                image: IMAGES.PROJECT0202
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type at Eastern Visayas Regional Medical Center - Mother & Child Hospital in Tacloban City in 2016.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 22,
        image: IMAGES.PROJECT021,
        title: 'Divine Mercy Wellness Center Inc.',
        body: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type at Divine Mercy Wellness Center Inc. in Tuguegarao City, Cagayan in 2016.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT021
            },
            {
                id: 2,
                image: IMAGES.PROJECT0211
            },
            {
                id: 3,
                image: IMAGES.PROJECT0212
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type at Divine Mercy Wellness Center Inc. in Tuguegarao City, Cagayan in 2016.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 23,
        image: IMAGES.PROJECT022,
        title: 'General Santos Doctors Hospital Inc.',
        body: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type and two (2) units of Medical Air System at General Santos Doctors Hospital Inc. in General Santos City in 2015.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT022
            },
            {
                id: 2,
                image: IMAGES.PROJECT0221
            },
            {
                id: 3,
                image: IMAGES.PROJECT0222
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type and two (2) units of Medical Air System at General Santos Doctors Hospital Inc. in General Santos City in 2015.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 24,
        image: IMAGES.PROJECT023,
        title: 'East Avenue Medical Center',
        body: 'Hundredfolds Industries Corp. installed four (4) units of Oxygen Generator Modular Type with Refilling Station at East Avenue Medical Center in Quezon City in 2014.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT023
            },
            {
                id: 2,
                image: IMAGES.PROJECT0231
            },
            {
                id: 3,
                image: IMAGES.PROJECT0232
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Hundredfolds Industries Corp. installed four (4) units of Oxygen Generator Modular Type with Refilling Station at East Avenue Medical Center in Quezon City in 2014.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 25,
        image: IMAGES.PROJECT024,
        title: 'Batanes General Hospital',
        body: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type with Refilling Station at Batanes General Hospital in Batanes in 2013.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT024
            },
            {
                id: 2,
                image: IMAGES.PROJECT0241
            },
            {
                id: 3,
                image: IMAGES.PROJECT0242
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type with Refilling Station at Batanes General Hospital in Batanes in 2013.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 26,
        image: IMAGES.PROJECT025,
        title: 'Culion Sanitarium & General Hospital',
        body: 'Two (2) units of Oxygen Generator Modular Type with Refilling Station were installed at Culion Sanitarium & General Hospital in Culion Palawan in 2013.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT025
            },
            {
                id: 2,
                image: IMAGES.PROJECT0251
            },
            {
                id: 3,
                image: IMAGES.PROJECT0252
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Two (2) units of Oxygen Generator Modular Type with Refilling Station were installed at Culion Sanitarium & General Hospital in Culion Palawan in 2013.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 27,
        image: IMAGES.PROJECT026,
        title: 'Ospital ng Palawan',
        body: 'Two (2) units of Oxygen Generator Modular Type with Refilling Station were installed at Ospital ng Palawan in Puerto Princesa City, Palawan in 2012.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT026
            },
            {
                id: 2,
                image: IMAGES.PROJECT0261
            },
            {
                id: 3,
                image: IMAGES.PROJECT0262
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Two (2) units of Oxygen Generator Modular Type with Refilling Station were installed at Ospital ng Palawan in Puerto Princesa City, Palawan in 2012.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 28,
        image: IMAGES.PROJECT027,
        title: 'Bicol Medical Center',
        body: 'Hundredfolds Industries Corp installed Two (2) units of Oxygen Generator Modular Type with Refilling Station at Bicol Medical Center in Naga City in 2012.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT027
            },
            {
                id: 2,
                image: IMAGES.PROJECT0271
            },
            {
                id: 3,
                image: IMAGES.PROJECT0272
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Hundredfolds Industries Corp installed Two (2) units of Oxygen Generator Modular Type with Refilling Station at Bicol Medical Center in Naga City in 2012.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 29,
        image: IMAGES.PROJECT028,
        title: 'Butuan Doctors Hospital',
        body: 'Hundredfolds Industries Corp. installed one (1) unit of Oxygen Generator Modular Type at Butuan Doctors Hospital in Butuan City in 2011.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT028
            },
            {
                id: 2,
                image: IMAGES.PROJECT0281
            },
            {
                id: 3,
                image: IMAGES.PROJECT0282
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Hundredfolds Industries Corp. installed one (1) unit of Oxygen Generator Modular Type at Butuan Doctors Hospital in Butuan City in 2011.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 30,
        image: IMAGES.PROJECT029,
        title: 'Teresita Lopez Jalandoni Provincial Hospital',
        body: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type atTeresita Lopez Jalandoni Provincial Hospital in Silay City, Negros Occidental in 2011.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT029
            },
            {
                id: 2,
                image: IMAGES.PROJECT0291
            },
            {
                id: 3,
                image: IMAGES.PROJECT0292
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Modular Type atTeresita Lopez Jalandoni Provincial Hospital in Silay City, Negros Occidental in 2011.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 31,
        image: IMAGES.PROJECT030,
        title: 'Apex Mining Co., Inc',
        body: 'Two (2) units of Oxygen Generator Modular Type were installed at Apex Mining Co., Inc in Compostela Valley in 2010.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT030
            },
            {
                id: 2,
                image: IMAGES.PROJECT0301
            },
            {
                id: 3,
                image: IMAGES.PROJECT0302
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Two (2) units of Oxygen Generator Modular Type were installed at Apex Mining Co., Inc in Compostela Valley in 2010.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 32,
        image: IMAGES.PROJECT031,
        title: 'Veterans Regional Hospital',
        body: 'Two (2) units of Oxygen Generator Modular Type with Refilling Station were installed at Veterans Regional Hospital in Bayombong, Nueva Vizcaya in 2010.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT031
            },
            {
                id: 2,
                image: IMAGES.PROJECT0311
            },
            {
                id: 3,
                image: IMAGES.PROJECT0312
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Two (2) units of Oxygen Generator Modular Type with Refilling Station were installed at Veterans Regional Hospital in Bayombong, Nueva Vizcaya in 2010.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 33,
        image: IMAGES.PROJECT032,
        title: 'Zamboanga City Medical Center',
        body: 'One (1) unit of Oxygen Generator Modular Type was installed at Zamboanga City Medical Center in Zamboanga City in 2009.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT032
            },
            {
                id: 2,
                image: IMAGES.PROJECT0321
            },
            {
                id: 3,
                image: IMAGES.PROJECT0322
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'One (1) unit of Oxygen Generator Modular Type was installed at Zamboanga City Medical Center in Zamboanga City in 2009.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 34,
        image: IMAGES.PROJECT033,
        title: 'Cotabato Regional & Medical Center',
        body: 'Hundredfolds Industries Corp. installed one (1) unit of Oxygen Generator Modular Type at Cotabato Regional & Medical Center in Sinsuat Avenue, Cotabato City in 2008.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT033
            },
            {
                id: 2,
                image: IMAGES.PROJECT0331
            },
            {
                id: 3,
                image: IMAGES.PROJECT0332
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Hundredfolds Industries Corp. installed one (1) unit of Oxygen Generator Modular Type at Cotabato Regional & Medical Center in Sinsuat Avenue, Cotabato City in 2008.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 35,
        image: IMAGES.PROJECT034,
        title: 'Davao Doctors Hospital',
        body: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Twin Tower Type  and Medical Air System at Davao Doctors Hospital in Davao City in 2007.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT034
            },
            {
                id: 2,
                image: IMAGES.PROJECT0341
            },
            {
                id: 3,
                image: IMAGES.PROJECT0342
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Twin Tower Type  and Medical Air System at Davao Doctors Hospital in Davao City in 2007.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 36,
        image: IMAGES.PROJECT035,
        title: 'Southern Philippines Medical Center (SPMC)',
        body: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Twin Tower Type at Southern Philippines Medical Center in Davao City in 2006.',        
        photos: [
            {
                id: 1,
                image: IMAGES.PROJECT035
            },
            {
                id: 2,
                image: IMAGES.PROJECT0351
            },
            {
                id: 3,
                image: IMAGES.PROJECT0352
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Hundredfolds Industries Corp. installed two (2) units of Oxygen Generator Twin Tower Type at Southern Philippines Medical Center in Davao City in 2006.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
]