import { IMAGES } from "../constants/Images";

export const services = [  
    {
        id: 1,
        image: IMAGES.SERVICE2,
        category: 'Service',
        title: 'Compressor Air\nAudit',
        body: 'Scheduled inspection and service routine to\nensure proper functioning and safety.',
        photos: [
            {
                id: 1,
                image: IMAGES.SERVICE21
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'A compressor air audit in oxygen service involves evaluating the compressed air system to ensure it meets safety standards for handling oxygen. The audit checks for potential contamination, verifies the compatibility of materials, assesses lubricants, inspects seals and gaskets, examines filtration systems, reviews system design, and ensures proper pressure and flow control.',
                        subContent: []
                    },
                    {
                        sectionDescription: 'The goal is to prevent hazards like combustion or explosions by maintaining the purity of the oxygen used in industrial processes.',
                        subContent: []
                    },
                ]            
            }
        ],
    },
    {
        id: 2,
        image: IMAGES.SERVICE1,
        category: 'Service',
        title: 'Annual Preventive\nMaintenance',
        body: 'Evaluating the compressed air system to ensure it\nmeets safety standards for handling oxygen.',
        photos: [
            {
                id: 1,
                image: IMAGES.SERVICE11
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Annual Preventive Maintenance for oxygen equipment is a scheduled inspection and service routine to ensure proper functioning and safety. Tasks include visual inspection, cleaning, functional testing, calibration, part replacement, safety checks, and documentation.',
                        subContent: []
                    },
                    {
                        sectionDescription: 'This is vital in healthcare to prevent equipment failure, comply with safety standards, and maintain reliable oxygen delivery. Following manufacturer guidelines is crucial for warranty and optimal performance.',
                        subContent: []
                    },
                ]
            }
        ],
    },
    {
        id: 3,
        image: IMAGES.SERVICE3,
        category: 'Service',
        title: 'Medical Gas Piping System',
        body: 'Medical gas piping service specializes in designing, installing, inspecting, and maintaining dedicated gas\ndelivery systems within healthcare facilities.',
        photos: [
            {
                id: 1,
                image: IMAGES.SERVICE31
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'A Medical Gas Piping System service involves maintaining, inspecting, and ensuring the proper functioning of the network that delivers medical gases to patient areas in healthcare facilities.',
                        subContent: []
                    },
                    {
                        sectionDescription: 'It includes activities like compliance checks, system inspections, and addressing any issues to guarantee a safe and reliable supply of medical gases.',
                        subContent: []
                    },
                ]            
            },
        ],
    },
    {
        id: 4,
        image: IMAGES.SERVICE4,
        category: 'Service',
        title: 'Compressed Air System Audit',
        body: 'It involves a review of components, like compressors and air quality control, to identify and address\nissues, enhance performance, and ensure cost-effectiveness.',
        photos: [
            {
                id: 1,
                image: IMAGES.SERVICE41
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'A Compressed Air System Audit service assesses and optimizes the efficiency of the compressed air system in an industrial setting. It involves a review of components, like compressors and air quality control, to identify and address issues, enhance performance, and ensure cost-effectiveness.',
                        subContent: []
                    },
                ]                   
            },
        ],
    },
    {
        id: 5,
        image: IMAGES.SERVICE5,
        category: 'Service',
        title: 'Air Compressor Repair or Installation',
        body: 'Involves fixing or setting up the equipment that compresses air for various industrial applications. It includes tasks like fixing malfunctions, replacing parts, or installing new compressors to ensure the efficient and reliable operation of the system.',
        photos: [
            {
                id: 1,
                image: IMAGES.SERVICE5
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Involves fixing or setting up the equipment that compresses air for various industrial applications. It includes tasks like fixing malfunctions, replacing parts, or installing new compressors to ensure the efficient and reliable operation of the system.',
                        subContent: []
                    },
                ]                   
            },
        ],
    },
    {
        id: 6,
        image: IMAGES.SERVICE6,
        category: 'Service',
        title: 'Air Dryer Repair or Installation',
        body: 'Fixing or setting up devices that remove moisture from compressed air systems in industries. This service ensures the proper functioning of air dryers, preventing issues like corrosion and maintaining the quality of compressed air.',
        photos: [
            {
                id: 1,
                image: IMAGES.SERVICE6
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Fixing or setting up devices that remove moisture from compressed air systems in industries. This service ensures the proper functioning of air dryers, preventing issues like corrosion and maintaining the quality of compressed air.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 7,
        image: IMAGES.SERVICE7,
        category: 'Service',
        title: 'Water Chiller Repair or Installation',
        body: 'Involves fixing or setting up cooling systems that maintain the temperature of equipment handling oxygen. This service ensures the proper functioning of water chillers, critical for safety and efficiency in oxygen-related processes.',
        photos: [
            {
                id: 1,
                image: IMAGES.SERVICE7
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Involves fixing or setting up cooling systems that maintain the temperature of equipment handling oxygen. This service ensures the proper functioning of water chillers, critical for safety and efficiency in oxygen-related processes.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
    {
        id: 8,
        image: IMAGES.SERVICE8,
        category: 'Service',
        title: 'Oxygen/Nitrogen Generator Repair or Installation',
        body: 'Involves fixing or setting up systems that produce oxygen or nitrogen. This service ensures the proper functioning of generators, critical for reliable and safe oxygen-related processes in various applications.',
        photos: [
            {
                id: 1,
                image: IMAGES.SERVICE8
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Involves fixing or setting up systems that produce oxygen or nitrogen. This service ensures the proper functioning of generators, critical for reliable and safe oxygen-related processes in various applications.',
                        subContent: []
                    },
                ]           
            },
        ],
    },
]