import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SX_STYLES } from '../../styles/components/general/CustomAccordionStyles';
import { COLORS } from '../../constants/Colors';
import { Box } from '@mui/material';

const CustomAccordion = (props) => {
    return (
            <Accordion variant='outlined' sx={SX_STYLES.accordionStyles} {...props}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={[SX_STYLES.title, {color: props.expanded ? COLORS.blueE7 : COLORS.black}]}>
                        {props.title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                       {props.description.map((item, index) =>
                            <Box key={index}>
                                {
                                    item.sectionDescription !== '' &&  
                                        <>
                                            {props.description[0].sectionDescription === '' && <br/>}
                                            <Typography sx={SX_STYLES.body}>
                                                {item.sectionDescription}
                                            </Typography>
                                            <br/>
                                        </>
                                }
                                {
                                    item.subContent.length > 0 &&
                                        item.subContent.map((subItem, i)=> 
                                            <Box key={i}>
                                                <Typography sx={SX_STYLES.body}>
                                                    {
                                                        subItem.sectionLabel !== '' && item.sectionDescription === '' ? 
                                                                <span><b>{subItem.sectionLabel}</b> - </span>
                                                            : 
                                                            subItem.sectionLabel !== '' && item.sectionDescription !== '' ? 
                                                                item.subContent.length === 1 ?
                                                                    <b>{subItem.sectionLabel} </b> 
                                                                :  
                                                                    <span>• &nbsp;<b>{subItem.sectionLabel}: </b></span>
                                                            :
                                                                <span>• &nbsp;</span>
                                                    }
                                                    {subItem.subDescription}
                                                </Typography> 
                                                { (item.sectionDescription === '' && 
                                                    subItem.subDescription !== '' && 
                                                        subItem.sectionLabel === '') || 
                                                            (item.sectionDescription !== '' && 
                                                                subItem.subDescription !== '' && 
                                                                    subItem.sectionLabel !== '') ||
                                                                        (item.sectionDescription !== '' && 
                                                                            subItem.subDescription !== '' && 
                                                                                subItem.sectionLabel === '')  ? 
                                                                        null
                                                                    :     
                                                                        <br/> 
                                                }
                                            </Box>
                                        )
                                }
                               
                            </Box>
                                    
                       )}
                                
                      
                 
                   
                </AccordionDetails>
            </Accordion>
    );
}

export default CustomAccordion;