import { FONTS } from "../../constants/Fonts";
import { SIZES } from "../../constants/Sizes";
import { SPACING } from "../../constants/Spacing";
import { COLORS } from '../../constants/Colors';
import { BORDER } from "../../constants/Border";

export const SX_STYLES = {
    container: {
        paddingTop: SPACING._64px,
        paddingBottom: SPACING._96px,
        paddingLeft: SPACING.x_large,
        paddingRight: SPACING.x_large,
    },
    sectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: SPACING._64px,
        paddingTop: SPACING._64px,
        paddingBottom: SPACING._64px,
    },
    sectionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: SPACING._20px
    },
    sectionLabel: {
        ...FONTS.font500,
        fontSize: SIZES._36px,
    },
    sectionSubLabel: {
        ...FONTS.font300,
        fontSize: SIZES._20px,
        color: COLORS.gray85
    },
    imageStyle:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: "cover",
        width: '100%',
        height: '40vh',
        marginTop: SPACING._64px,
        marginBottom: SPACING._64px,
    },
    sectionContainer1: {
        display: 'flex',
        flexDirection: 'column',
        gap: SPACING._64px,
        marginTop: SPACING._64px,
        marginBottom: SPACING._64px,
        paddingBottom: SPACING._64px,
    },
    sectionWrapper1: {
        display: 'flex',
        flexDirection: 'column',
        gap: SPACING._20px
    },
    rowContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    rowContainerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: SPACING._40px
    },
    rowContainer1: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    description: {
        ...FONTS.font300,
        fontSize: SIZES._18px,
        whiteSpace: 'pre-line', 
        color: COLORS.gray85
    },
    companyInstallContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '3.2vh 2.4vh 3.2vh 2.4vh',
        backgroundColor: COLORS.blur4D,
        border: `1px solid ${COLORS.blur4D}`,
        backdropFilter: 'blur(6px)',
        gap: SPACING._12px
    },
    installNo: {
        ...FONTS.font600,
        fontSize: SIZES._60px,
        color: COLORS.white,
        opacity: 0.9
    },
    projectInstalledText: {
        ...FONTS.font600,
        fontSize: SIZES._18px,
        color: COLORS.white,
        opacity: 0.7
    },
    successfulText: {
        ...FONTS.font400,
        fontSize: SIZES._18px,
        color: COLORS.white,
        whiteSpace: 'pre-line',
        textAlign: 'center',
        opacity: 0.7
    },
    coreContainer: {
        background: COLORS.lightBlueFF, 
        padding: SPACING._24px,
        width: '34vh',
        marginRight: SPACING._24px
    },
    coreIconContainer: {
        display:'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        background: COLORS.blueF2, 
        width: '4.8vh', 
        height: '4.8vh', 
        borderRadius: BORDER._8px,
        marginBottom: SPACING._96px
    },
    coreTitle: {
        ...FONTS.font400,
        fontSize: SIZES._20px,
        color: COLORS.blueD66
    },
    coreBody: {
        ...FONTS.font300,
        fontSize: SIZES._16px,
        color: COLORS.blueD4,
    },
    sectionMapContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: SPACING._64px,
        paddingTop: SPACING._96px,
        paddingBottom: SPACING._96px,
        backgroundColor: COLORS.lightGrayFB
    },
    sectionMapContainer1: {
        display: 'flex',
        flexDirection: 'column',
        gap: SPACING._64px,
        marginBottom: SPACING._96px,
        paddingTop: SPACING._96px,
        paddingBottom: SPACING._96px,
        paddingLeft: SPACING.x_large,
        paddingRight: SPACING.x_large,
        backgroundColor: COLORS.white,
    },
    sectionLabel1: {
        ...FONTS.font600,
        fontSize: SIZES._48px,
    },
    talkToUsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: SPACING._20px,
        width: '38.4vh'
    },
    talkToUsWrapper: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    talkToUsSectionText: {
        ...FONTS.font400,
        fontSize: SIZES._20px,
    },
    talkToUsSectionDesc: {
        ...FONTS.font300,
        fontSize: SIZES._16px,
        color: COLORS.gray85,
        marginTop: SPACING._8px,
    },
    talkToUsSectionContact: {
        ...FONTS.font400,
        fontSize: SIZES._16px,
        color: COLORS.blueE7,
        whiteSpace: 'pre-line',
        textAlign: 'center'
    },
    mapStyle: {
        marginTop: SPACING._48px,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: "cover",
        width: '30.3vh',
        height: '52.2vh'
    },
    toolTip1: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '6vh', 
        marginLeft: '-4.6vh'
    },
    toolTip2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '6.5vh', 
        marginLeft: '22.3vh'
    }
}