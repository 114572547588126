import { BORDER } from "../../../constants/Border";
import { COLORS } from "../../../constants/Colors";
import { FONTS } from "../../../constants/Fonts";
import { SIZES } from "../../../constants/Sizes";
import { SPACING } from "../../../constants/Spacing";

export const SX_STYLES = {
    container: {
        marginBottom: SPACING._64px,
    },
    chevronStyle: {
        color: COLORS.grayDD
    },
    crumbStyle: {
        ...FONTS.font500,
        fontSize: SIZES._14px,
        color: COLORS.gray85
    },
    currentCrumb: {
        ...FONTS.font500,
        fontSize: SIZES._14px,
        color: COLORS.blueE7, 
        paddingRight: SPACING._20px, 
        paddingLeft: SPACING._20px,
        paddingTop: '0.8vh', 
        paddingBottom: '0.8vh', 
        borderRadius: BORDER._8px, 
        background: COLORS.lightBlueFF,
    }

   
}