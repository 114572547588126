import { COLORS } from "../../../constants/Colors";
import { FONTS } from "../../../constants/Fonts";
import { SIZES } from "../../../constants/Sizes";
import { SPACING } from "../../../constants/Spacing";

export const SX_STYLES = {
    imageContainer: {
        width: '32.2vh', 
        height: '20vh'
    },
    imageStyle: {
        objectFit: 'cover',
        width: '32.2vh', 
        height: '20vh'
    },
    container: {
        display: 'flex',
        height: '20vh',
        width: '45vw',
    },
    sectionContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        gap: SPACING._8px, 
        marginLeft: SPACING._24px
    },
    date: {
        ...FONTS.font500,
        fontSize: SIZES._14px,
        color: COLORS.violet
    },
    hospital: {
        ...FONTS.font500,
        fontSize: SIZES._24px,
    },
    hospitalDescription: {
        ...FONTS.font300,
        fontSize: SIZES._16px,
        color: COLORS.gray85
    }
}