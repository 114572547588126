import React, { useContext } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';
import { ServicesContext } from '../provider/ServicesProvider';
import { ChevronRight } from '@mui/icons-material';
import { SX_STYLES } from '../../styles/components/listItems/ListBreadCrumbsStyles';
import { LABELS } from '../../constants/Labels';
import { useNavigate } from 'react-router-dom';

const ListBreadCrumbs = (props) => {
    const servicesContext = useContext(ServicesContext);
    const navigate = useNavigate();
    const onClickEvent = (event, item) => {
        event.preventDefault();
        var filtered = servicesContext.breadCrumbs.filter(function(el) { return el.crumb !== LABELS.PRODUCTS && el.crumb !== LABELS.SERVICES && el.crumb !== LABELS.INSTALLED_PROJECTS}); 
        servicesContext.setBreadCrumbs(filtered);
        if(item.crumb === LABELS.PRODUCTS || item.crumb === LABELS.SERVICES || item.crumb === LABELS.INSTALLED_PROJECTS){
            navigate(-1);
        }else{
            navigate('/', {replace: true});
        }
    }

    return (
        <Breadcrumbs sx={SX_STYLES.container} separator={<ChevronRight sx={SX_STYLES.chevronStyle}/>}>
            {
                props.data.map((item, index) => 
                    <Link sx={SX_STYLES.crumbStyle} key={index} underline="hover" href="/" onClick={(event) => onClickEvent(event, item)}>
                        {item.crumb}
                    </Link>  
                )
            }
                <Typography sx={SX_STYLES.currentCrumb}>
                    {props.currentCrumb}
                </Typography>
        </Breadcrumbs>
    );
}

export default ListBreadCrumbs;