import { COLORS } from "../../../constants/Colors"
import { FONTS } from "../../../constants/Fonts"
import { SIZES } from "../../../constants/Sizes"

export const SX_STYLES = {
    navLinkStyles: {
        ...FONTS.font400,
        fontSize: SIZES._16px,
        color: COLORS.blueD66,
        textTransform: 'none'
    }
}