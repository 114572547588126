import { SPACING } from "../../../constants/Spacing";
import { SIZES } from '../../../constants/Sizes';
import { COLORS } from "../../../constants/Colors";
import { FONTS } from "../../../constants/Fonts";

export const SX_STYLES = {
    logoContainer: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center', 
        marginLeft: SPACING.x_large,
        marginRight: SPACING._22px,
    },
    logoStyle: {
        height: '4.2vh',
        marginRight: SPACING._6px
    },
    logoName: {
        ...FONTS.font600,
        fontSize: SIZES._16px,
        color: COLORS.blueD66,
    },
    footerContainer: {
        marginTop: SPACING._48px,
    },
    contactContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: SPACING._48px,
        paddingBottom: SPACING._48px,
        paddingLeft: SPACING.x_large,
        paddingRight: SPACING.x_large,
        background: COLORS.blueD4
    },
    footerSectionLabel: {
        ...FONTS.font500,
        fontSize: SIZES._30px,
        color: COLORS.white
    },
    footerSubLabel: {
        ...FONTS.font300,
        fontSize: SIZES._20px,
        color: COLORS.grayEA, 
        marginTop: SPACING._16px
    },
    emailContainer: {
        display: 'flex', 
        gap: SPACING._16px
    },
    textFieldStyle: {
        height: '5.8vh',
        background: COLORS.white, 
        borderRadius: 2, 
        border: 0 
    },
    emailInput: {
        sx: {
            ...FONTS.font300,
            fontSize: SIZES._16px,
            height: '6vh'
        }
    },
    footerLinksContainer:{
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: SPACING.x_large,
        paddingRight: SPACING.x_large,
        paddingTop: SPACING._64px,
        paddingBottom: '4.5vh',
        gap: SPACING._64px,
    },
    footerLinksWrapper: {
        display: 'flex', 
        gap: '3vw',
    },
    footerCompanyDescription: {
        ...FONTS.font300,
        fontSize: SIZES._16px,
        marginTop: SPACING._20px,
    },
    linkContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        gap: SPACING._16px,
        marginRight: '10vw', 
    },
    divider: {
        backgroundColor: COLORS.grayEA
    },
    copyRightContainer: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        marginTop: SPACING._32px,
    },
    copyRight: {
        ...FONTS.font300,
        fontSize: SIZES._16px,
        color: COLORS.gray98
    },
    socialIconContainer: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center', 
        gap: '2.4vh'
    },
    twitter: {
        color: COLORS.gray98, 
        fontSize: '3.5vh'
    }
}