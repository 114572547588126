import React, { createContext, useState } from 'react';
import { ROUTES } from '../../constants/Routes';

const ServicesContext = createContext(); 

const ServicesProvider = (props) => {
    const [breadCrumbs, setBreadCrumbs] = useState([{crumb: 'Home', navigateTo: ROUTES.HOME}]);
    const [productData, setProductData] = useState([]);
    const [isClickedContact, setIsClickedContact] = useState(false);

    const servicesContext = {
       breadCrumbs, 
       setBreadCrumbs,
       productData, 
       setProductData,
       isClickedContact, 
       setIsClickedContact
    };

    return (
        <ServicesContext.Provider value={servicesContext}>
            {props.children}
        </ServicesContext.Provider>
    );
}

export { ServicesProvider, ServicesContext };