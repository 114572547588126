import { Box, Button, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { IMAGES } from '../../constants/Images';
import { SX_STYLES } from '../../styles/components/header/NavigationBarStyles';
import { services } from '../../helper/navigation';
import { ServicesContext } from '../provider/ServicesProvider';
import { ROUTES } from '../../constants/Routes';
import RoundedOptionButton from '../cores/RoundedOptionButton';
import { LABELS } from '../../constants/Labels';
import { useNavigate } from 'react-router-dom';

const  NavigationBar = (props) => {
    const servicesContext = useContext(ServicesContext);
    const navigate = useNavigate();

    const handleClick = (val) => {
        servicesContext.setBreadCrumbs([{crumb: LABELS.HOME, navigateTo: ROUTES.HOME}]);
        navigate(val.route);
        if(val.route === ROUTES.ABOUT_US){
            servicesContext.setIsClickedContact(false)
        }
    }

    const goToContactUs = () => {
        navigate(ROUTES.ABOUT_US);
        servicesContext.setIsClickedContact(true);
    }
    const handleContactUs = () => {
        navigate(ROUTES.ABOUT_US,   { state: { targetId: "talktous" }  })
        
    }
    
    return (
        <Box sx={SX_STYLES.navigationContainer}>
            <Box sx={SX_STYLES.navigationWrapper}>
                <Box sx={SX_STYLES.logoContainer} onClick={()=>navigate('/')}>
                    <Box
                        component="img"
                        sx={SX_STYLES.logoStyle}
                        src={IMAGES.HIC_Logo}
                    />
                    <Typography sx={SX_STYLES.logoName}>HUNDREDFOLDS</Typography>
                </Box>
                {
                    services.map((item, index) => 
                        <Box key={index}>
                            <RoundedOptionButton text={item.service} endIcon={item.icon} onClick={() => handleClick(item)}/>
                        </Box>
                    )
                }
            </Box>
            <Button variant="text" sx={SX_STYLES.buttonContact} onClick={goToContactUs}>Contact Us</Button>
        </Box>
    );
}

export default NavigationBar;