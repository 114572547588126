import React from 'react';
import { Box } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import ListItemImage from '../listItems/ListItemImage';
import { SX_STYLES } from '../../styles/components/general/ImageCarouselStyles';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

const ImageCarousel = (props) => {
    const goToProductDetails = (val) => {
        props.productDetails(val)
    }
    return (
        <Box sx={[SX_STYLES.container, props.containerStyle]} onClick={props.onClick}>
            <Carousel autoPlay swipe={false} NextIcon={<ArrowForward sx={SX_STYLES.icon}/>} PrevIcon={<ArrowBack sx={SX_STYLES.icon}/>} navButtonsAlwaysVisible navButtonsProps={SX_STYLES.navButtonsPropsStyle} indicatorIconButtonProps={SX_STYLES.indicatorIconButtonProps} indicatorContainerProps={SX_STYLES.indicatorContainerProps} {...props}>
                {
                    props.data?.map((item, i) => 
                        <Box key={i}>
                            <ListItemImage 
                                itemID={item.id} 
                                image={item.image} 
                                category={item.category} 
                                title={item.title} 
                                body={item.body} 
                                noRadius={props.noRadius} 
                                height={props.height}
                                imageHeight={props.imageHeight}
                                onClick={()=>goToProductDetails(item)}
                            /> 
                        </Box>
                    )
                }
            </Carousel>
        </Box>
    );
}

export default ImageCarousel;