import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { SX_STYLES } from '../../styles/pages/ProductDetailsStyles';
import CustomAccordion from '../../components/general/CustomAccordion';
import ImageCarousel from '../../components/general/ImageCarousel';
import { ServicesContext } from '../../components/provider/ServicesProvider';
import ListBreadCrumbs from '../../components/listItems/ListBreadCrumbs';
import { COLORS } from '../../constants/Colors';
import { useNavigate } from 'react-router-dom';

const ProductDetails = (props) => {
    const servicesContext = useContext(ServicesContext);
    const [index, setIndex] = useState(0);
    const [isClicked, setIsClicked] = useState(false);
    const [expanded, setExpanded] = useState('Description');
    const navigate = useNavigate();
 
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    let productItem = servicesContext?.productData?.productItem;

    useEffect(() => {
        window?.scrollTo({top: 0, left: 0, behavior: 'instant'});
        if(productItem?.title === undefined){
            navigate(-1)
        }
    }, [])
  
    const handleClick = (index) => {
        setIndex(index)
    }

    const handleClickZoomSubPhotos = (val) => {
        setIsClicked(true);
        setIndex(val);
    }

    const handleCloseZoom = (val) => {
        setIsClicked(false);
    }

    const handleClickMore = () => {
        setIsClicked(true);
    }

    return (
        <>
            {isClicked && 
                <>
                    <Box sx={SX_STYLES.zoomBackdrop} onClick={()=>handleCloseZoom(0)}/>
                    <Box sx={SX_STYLES.zoomCarouselContainer}>
                        <Box sx={SX_STYLES.zoomCarouselWrapper}>
                            <ImageCarousel
                                index={index} 
                                cycleNavigation={false}
                                data={productItem?.photos} 
                                next={(now, previous) => setIndex(now)} 
                                prev={(now, previous) => setIndex(now)} 
                                indicators={false}
                                autoPlay={false} 
                                containerStyle={SX_STYLES.carouselContainer1} 
                                height={'54.2vh'}
                                imageHeight={'contain'}
                                noRadius
                                onClick={()=>handleCloseZoom(0)}
                            />
                            {
                                productItem?.photos.length > 1 && 
                                    <Box sx={[SX_STYLES.allPhotosContainer, {justifyContent: 'center'}]}>
                                        {
                                            productItem?.photos.map((item, i) =>
                                                <Box key={i} component='div' height={'12.5vh'} width={'12.5vh'} sx={[SX_STYLES.allPhotosStyle, { backgroundImage: `url(${item.image})`, border: 3, borderColor: index === i ? COLORS.grayDD : COLORS.transparent}]} onClick={() => handleClick(i)}/>        
                                            )
                                        }          
                                    </Box>
                            }
                            
                        </Box>
                    </Box>
                </>
            }
            <Box sx={SX_STYLES.container}>
                <Box sx={{height: '8.8vh'}}/>
                <ListBreadCrumbs data={servicesContext.breadCrumbs} currentCrumb={productItem?.title} />
                <Box sx={SX_STYLES.rowContainer}>
                    <Box>
                        {!isClicked && <Box sx={SX_STYLES.zoom} onClick={() => setIsClicked(true)}/>}
                        <Typography sx={SX_STYLES.sectionLabel}>{productItem?.title}</Typography>
                        <ImageCarousel 
                            index={index}
                            cycleNavigation={false}
                            data={productItem?.photos} 
                            next={(now, previous) => setIndex(now)} 
                            prev={(now, previous) => setIndex(now)}
                            indicators={false} 
                            autoPlay={false} 
                            containerStyle={SX_STYLES.carouselContainer} 
                            height={'54.2vh'}
                            noRadius
                        />
                        {
                            productItem?.photos.length > 1 && 
                                <Box sx={SX_STYLES.allPhotosContainer}>
                                    {
                                        productItem?.photos.slice(0, 3).map((item, i) =>
                                            <Box key={i} sx={{display: 'flex'}}>
                                                <>
                                                    <Box 
                                                        component='div' 
                                                        height={'12.5vh'} 
                                                        width={'12.5vh'} 
                                                        sx={[SX_STYLES.allPhotosStyle, 
                                                                {
                                                                    backgroundImage: `url(${item.image})`, 
                                                                    border: i === index ? 3 : 0,
                                                                    borderColor: index === i ? COLORS.grayDD : COLORS.transparent
                                                                }]
                                                        } 
                                                        onClick={() => handleClickZoomSubPhotos(i)}
                                                    >
                                                        {
                                                            i === 2 && productItem?.photos.length > 3 &&
                                                            <Box onClick={handleClickMore}>
                                                                <Box sx={SX_STYLES.boxOpacity} />
                                                                <Typography sx={SX_STYLES.more}>+1 more</Typography>
                                                            </Box>
                                                        }
                                                    </Box>        
                                                </>
                                            </Box>
                                        )
                                    }          
                                </Box>
                        }
                        
                    </Box>
                    <Box sx={SX_STYLES.accordionContainer}>
                        {productItem?.details.map((item, i) => {
                            return <Box key={i}>
                                        <CustomAccordion
                                            expanded={expanded === item.accordionLabel}
                                            onChange={handleChange(item.accordionLabel)}
                                            title={item.accordionLabel}
                                            description={item.description}
                                        />
                                    </Box>
                        })}
                        
                    </Box>
                </Box>
            </Box>
        </>
     
    );
}

export default ProductDetails;