import { SPACING } from "../../../constants/Spacing";
import { BORDER } from '../../../constants/Border';
import { SIZES } from '../../../constants/Sizes';

export const SX_STYLES = {
    btnStyle: {
        fontSize: SIZES._16px,
        textTransform:'none',
        paddingTop: SPACING._12px,
        paddingBottom: SPACING._12px,
        paddingRight: SPACING._20px,
        paddingLeft: SPACING._20px,
        borderRadius: BORDER._8px,
    },
}