import React from 'react';
import ListLinks from '../../components/listItems/ListLinks';
import { link1, link2, link3 } from '../../helper/links';
import { Twitter } from '@mui/icons-material';
import { Box, Divider, TextField, Typography } from '@mui/material';
import RoundedButton from '../cores/RoundedButton';
import { IMAGES } from '../../constants/Images';
import { SPACING } from '../../constants/Spacing';
import { SX_STYLES } from '../../styles/components/footer/FooterSocialLinksStyles';

const FooterSocialLinks = (props) => {
    return (
        <Box>
            <Box sx={SX_STYLES.footerContainer}>
                <Box sx={SX_STYLES.contactContainer}>
                    <Box>
                        <Typography sx={SX_STYLES.footerSectionLabel}>Contact us</Typography>
                        <Typography sx={SX_STYLES.footerSubLabel}>Stay up to date with the latest news, announcements, and articles.</Typography>
                    </Box>
                    <Box sx={SX_STYLES.emailContainer}>
                        <TextField placeholder='Enter your email' InputProps={SX_STYLES.emailInput} sx={SX_STYLES.textFieldStyle} />
                        <RoundedButton text='Subscribe'/>
                    </Box>
                </Box>
            </Box>
            <Box sx={SX_STYLES.footerLinksContainer}>
                <Box sx={SX_STYLES.footerLinksWrapper}>
                    <Box sx={{marginRight: SPACING._64px}}>
                        <Box sx={[SX_STYLES.logoContainer, {marginLeft: 0}]}>
                            <Box
                                component="img"
                                sx={SX_STYLES.logoStyle}
                                src={IMAGES.HIC_Logo}
                            />
                            <Typography sx={SX_STYLES.logoName}>HUNDREDFOLDS</Typography>
                        </Box>
                        <Typography sx={SX_STYLES.footerCompanyDescription}>Empowering Industry & Healthcare: Your Source for Oxygen, Nitrogen, and More.</Typography>
                    </Box>
                    <Box sx={SX_STYLES.linkContainer}>
                        {
                            link1.map((item, index) => 
                                <Box key={index}>
                                    <ListLinks itemID={item.id} link={item.link}/>
                                </Box>
                            )
                        }
                    </Box>
                    <Box sx={SX_STYLES.linkContainer}>
                        {
                            link2.map((item, index) => 
                                <Box key={index}>
                                    <ListLinks itemID={item.id} link={item.link} to={item.url}/>
                                </Box>
                            )
                        }
                    </Box>
                    {/* <Box sx={SX_STYLES.linkContainer}>
                        {
                            link3.map((item, index) => 
                                <Box key={index}>
                                    <ListLinks itemID={item.id} link={item.link}/>
                                </Box>
                            )
                        }
                    </Box> */}
                </Box>
                <Box>
                    <Divider sx={SX_STYLES.divider}/>
                    <Box sx={SX_STYLES.copyRightContainer}>
                        <Typography sx={SX_STYLES.copyRight}>© 2023 Hundredfolds Industries Corporation. All rights reserved.</Typography>
                        {/* <Box sx={SX_STYLES.socialIconContainer}>
                            <Twitter sx={SX_STYLES.twitter}  />
                            <Box component='img' src={IMAGES.FACEBOOK} height={'3vh'}/>
                        </Box> */}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default FooterSocialLinks;
