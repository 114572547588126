import HIC_Logo from '../assets/logo/HIC_Logo.png';
import COAIRE from '../assets/logo/coaire.png';
import KYUNGWON from '../assets/logo/kyungwon.png';
import MEDIMAX from '../assets/logo/medimax.png';
import NOXERIOR from '../assets/logo/noxerior.png';
import FST from '../assets/logo/fst.png';
import TIGER from '../assets/logo/tiger.png';
import BEKO from '../assets/logo/beko.png';
import OMEGA from '../assets/logo/omega.png';

//Products
import OXYGEN from '../assets/images/products/oxygen.webp';
import OXYGEN_CONCENTRATORS from '../assets/images/products/oxygen_concentrators.webp';
import GAS_MONITORING from '../assets/images/products/gas_monitoring.webp';
import GAS_MONITORING1 from '../assets/images/products/gas_monitoring1.webp';
import GAS_MONITORING2 from '../assets/images/products/gas_monitoring2.webp';
import AUTOMATIC_MANIFOLD from '../assets/images/products/automatic_manifold.webp';
import AUTOMATIC_MANIFOLD1 from '../assets/images/products/automatic_manifold1.webp';
import AUTOMATIC_MANIFOLD2 from '../assets/images/products/automatic_manifold2.webp';
import GAS_ALARM from '../assets/images/products/gas_alarm.webp';
import GAS_ALARM1 from '../assets/images/products/gas_alarm1.webp';
import BED_HEAD from '../assets/images/products/bed_unit.webp';
import BED_HEAD1 from '../assets/images/products/bed_unit1.webp';
import BED_HEAD2 from '../assets/images/products/bed_unit2.webp';
import BED_HEAD3 from '../assets/images/products/bed_unit3.webp';
import BED_HEAD4 from '../assets/images/products/bed_unit4.webp';


//Services
import SERVICE1 from '../assets/images/services/service1.webp';
import SERVICE2 from '../assets/images/services/service2.webp';
import SERVICE3 from '../assets/images/services/service3.webp';
import SERVICE4 from '../assets/images/services/service4.webp';
import SERVICE5 from '../assets/images/services/service5.webp';
import SERVICE6 from '../assets/images/services/service6.webp';
import SERVICE7 from '../assets/images/services/service7.webp';
import SERVICE8 from '../assets/images/services/service8.webp';
import SERVICE11 from '../assets/images/services/service11.webp';
import SERVICE21 from '../assets/images/services/service21.webp';
import SERVICE31 from '../assets/images/services/service31.webp';
import SERVICE41 from '../assets/images/services/service41.webp';

//Projects
import PROJECT1 from '../assets/images/projects/project1.webp';
import PROJECT11 from '../assets/images/projects/project11.webp';
import PROJECT12 from '../assets/images/projects/project12.webp';
import PROJECT2 from '../assets/images/projects/project2.webp';
import PROJECT21 from '../assets/images/projects/project21.webp';
import PROJECT22 from '../assets/images/projects/project22.webp';
import PROJECT3 from '../assets/images/projects/project3.webp';
import PROJECT31 from '../assets/images/projects/project31.webp';
import PROJECT32 from '../assets/images/projects/project32.webp';
import PROJECT4 from '../assets/images/projects/project4.webp';
import PROJECT41 from '../assets/images/projects/project41.webp';
import PROJECT42 from '../assets/images/projects/project42.webp';
import PROJECT5 from '../assets/images/projects/project5.webp';
import PROJECT51 from '../assets/images/projects/project51.webp';
import PROJECT52 from '../assets/images/projects/project52.webp';
import PROJECT6 from '../assets/images/projects/project6.webp';
import PROJECT61 from '../assets/images/projects/project61.webp';
import PROJECT62 from '../assets/images/projects/project62.webp';
import PROJECT7 from '../assets/images/projects/project7.webp';
import PROJECT71 from '../assets/images/projects/project71.webp';
import PROJECT72 from '../assets/images/projects/project72.webp';
import PROJECT8 from '../assets/images/projects/project8.webp';
import PROJECT81 from '../assets/images/projects/project81.webp';
import PROJECT82 from '../assets/images/projects/project82.webp';
import PROJECT9 from '../assets/images/projects/project9.webp';
import PROJECT91 from '../assets/images/projects/project91.webp';
import PROJECT92 from '../assets/images/projects/project92.webp';
import PROJECT10 from '../assets/images/projects/project10.webp';
import PROJECT101 from '../assets/images/projects/project101.webp';
import PROJECT102 from '../assets/images/projects/project102.webp';
import PROJECT011 from '../assets/images/projects/project011.webp';
import PROJECT0111 from '../assets/images/projects/project0111.webp';
import PROJECT0112 from '../assets/images/projects/project0112.webp';
import PROJECT012 from '../assets/images/projects/project012.webp';
import PROJECT0121 from '../assets/images/projects/project0121.webp';
import PROJECT0122 from '../assets/images/projects/project0121.webp';
import PROJECT013 from '../assets/images/projects/project013.webp';
import PROJECT0131 from '../assets/images/projects/project0131.webp';
import PROJECT0132 from '../assets/images/projects/project0132.webp';
import PROJECT014 from '../assets/images/projects/project014.webp';
import PROJECT0141 from '../assets/images/projects/project0141.webp';
import PROJECT0142 from '../assets/images/projects/project0142.webp';
import PROJECT015 from '../assets/images/projects/project015.webp';
import PROJECT0151 from '../assets/images/projects/project0151.webp';
import PROJECT0152 from '../assets/images/projects/project0152.webp';
import PROJECT016 from '../assets/images/projects/project016.webp';
import PROJECT0161 from '../assets/images/projects/project0161.webp';
import PROJECT0162 from '../assets/images/projects/project0162.webp';
import PROJECT017 from '../assets/images/projects/project017.webp';
import PROJECT0171 from '../assets/images/projects/project0171.webp';
import PROJECT0172 from '../assets/images/projects/project0172.webp';
import PROJECT018 from '../assets/images/projects/project018.webp';
import PROJECT0181 from '../assets/images/projects/project0181.webp';
import PROJECT0182 from '../assets/images/projects/project0182.webp';
import PROJECT019 from '../assets/images/projects/project019.webp';
import PROJECT0191 from '../assets/images/projects/project0191.webp';
import PROJECT0192 from '../assets/images/projects/project0192.webp';
import PROJECT020 from '../assets/images/projects/project020.webp';
import PROJECT0201 from '../assets/images/projects/project0201.webp';
import PROJECT0202 from '../assets/images/projects/project0202.webp';
import PROJECT021 from '../assets/images/projects/project021.webp';
import PROJECT0211 from '../assets/images/projects/project0211.webp';
import PROJECT0212 from '../assets/images/projects/project0212.webp';
import PROJECT022 from '../assets/images/projects/project022.webp';
import PROJECT0221 from '../assets/images/projects/project0221.webp';
import PROJECT0222 from '../assets/images/projects/project0222.webp';
import PROJECT023 from '../assets/images/projects/project023.webp';
import PROJECT0231 from '../assets/images/projects/project0231.webp';
import PROJECT0232 from '../assets/images/projects/project0232.webp';
import PROJECT024 from '../assets/images/projects/project024.webp';
import PROJECT0241 from '../assets/images/projects/project0241.webp';
import PROJECT0242 from '../assets/images/projects/project0242.webp';
import PROJECT025 from '../assets/images/projects/project025.webp';
import PROJECT0251 from '../assets/images/projects/project0251.webp';
import PROJECT0252 from '../assets/images/projects/project0252.webp';
import PROJECT026 from '../assets/images/projects/project026.webp';
import PROJECT0261 from '../assets/images/projects/project0261.webp';
import PROJECT0262 from '../assets/images/projects/project0262.webp';
import PROJECT027 from '../assets/images/projects/project027.webp';
import PROJECT0271 from '../assets/images/projects/project0271.webp';
import PROJECT0272 from '../assets/images/projects/project0272.webp';
import PROJECT028 from '../assets/images/projects/project028.webp';
import PROJECT0281 from '../assets/images/projects/project0281.webp';
import PROJECT0282 from '../assets/images/projects/project0282.webp';
import PROJECT029 from '../assets/images/projects/project029.webp';
import PROJECT0291 from '../assets/images/projects/project0291.webp';
import PROJECT0292 from '../assets/images/projects/project0292.webp';
import PROJECT030 from '../assets/images/projects/project030.webp';
import PROJECT0301 from '../assets/images/projects/project0301.webp';
import PROJECT0302 from '../assets/images/projects/project0302.webp';
import PROJECT031 from '../assets/images/projects/project031.webp';
import PROJECT0311 from '../assets/images/projects/project0311.webp';
import PROJECT0312 from '../assets/images/projects/project0312.webp';
import PROJECT032 from '../assets/images/projects/project032.webp';
import PROJECT0321 from '../assets/images/projects/project0321.webp';
import PROJECT0322 from '../assets/images/projects/project0322.webp';
import PROJECT033 from '../assets/images/projects/project033.webp';
import PROJECT0331 from '../assets/images/projects/project0331.webp';
import PROJECT0332 from '../assets/images/projects/project0332.webp';
import PROJECT034 from '../assets/images/projects/project034.webp';
import PROJECT0341 from '../assets/images/projects/project0341.webp';
import PROJECT0342 from '../assets/images/projects/project0342.webp';
import PROJECT035 from '../assets/images/projects/project035.webp';
import PROJECT0351 from '../assets/images/projects/project0351.webp';
import PROJECT0352 from '../assets/images/projects/project0352.webp';
import PROJECT036 from '../assets/images/projects/project036.webp';
import PROJECT0361 from '../assets/images/projects/project0361.webp';
import PROJECT0362 from '../assets/images/projects/project0362.webp';

//AboutUs
import COMPANY from '../assets/images/aboutus/company.webp';
import TEAM from '../assets/images/aboutus/team.webp';
import MISSION from '../assets/images/aboutus/mission.webp';
import VISION from '../assets/images/aboutus/vision.webp';
import MAP from '../assets/images/aboutus/map.webp';
import TOOLTIP1 from '../assets/images/aboutus/tooltip1.webp';
import TOOLTIP2 from '../assets/images/aboutus/tooltip2.webp';

//Backgrounds
import SECTION1 from '../assets/images/background/section1.webp';
import SECTION2 from '../assets/images/background/section2.webp';

//Banners
import BANNER1 from '../assets/images/banners/banner1.webp';
import BANNER2 from '../assets/images/banners/banner2.webp';
import BANNER3 from '../assets/images/banners/banner3.webp';
import BANNER4 from '../assets/images/banners/banner4.webp';

//Hospitals
import HOSPITAL1 from '../assets/images/hospitals/hospital1.webp';
import HOSPITAL2 from '../assets/images/hospitals/hospital2.webp';
import HOSPITAL3 from '../assets/images/hospitals/hospital3.webp';

//Award
import AWARD from '../assets/images/award/award.webp';

//Icons
import FACEBOOK from '../assets/icons/facebook.png';
import SEARCH from '../assets/icons/search.png';
import HONESTY from '../assets/icons/honesty.png';
import INNOVATION from '../assets/icons/innovation.png';
import COLLABORATION from '../assets/icons/collaboration.png';
import EMAIL from '../assets/icons/email.png';
import LOCATION from '../assets/icons/location.png';
import CONTACT from '../assets/icons/contact.png';

export const IMAGES = {
    HIC_Logo: HIC_Logo,
    COAIRE: COAIRE,
    KYUNGWON: KYUNGWON,
    MEDIMAX: MEDIMAX,
    NOXERIOR: NOXERIOR,
    FST: FST,
    TIGER: TIGER,
    BEKO: BEKO,
    OMEGA: OMEGA,
    OXYGEN: OXYGEN,
    SECTION1: SECTION1,
    SECTION2: SECTION2,
    BANNER1: BANNER1,
    BANNER2: BANNER2,
    BANNER3: BANNER3,
    BANNER4: BANNER4,
    HOSPITAL1: HOSPITAL1,
    HOSPITAL2: HOSPITAL2,
    HOSPITAL3: HOSPITAL3,
    AWARD: AWARD,
    FACEBOOK: FACEBOOK,
    SEARCH: SEARCH,
    OXYGEN_CONCENTRATORS: OXYGEN_CONCENTRATORS,
    GAS_MONITORING: GAS_MONITORING,
    GAS_MONITORING1: GAS_MONITORING1,
    GAS_MONITORING2: GAS_MONITORING2,
    AUTOMATIC_MANIFOLD: AUTOMATIC_MANIFOLD,
    AUTOMATIC_MANIFOLD1: AUTOMATIC_MANIFOLD1,
    AUTOMATIC_MANIFOLD2: AUTOMATIC_MANIFOLD2,
    GAS_ALARM: GAS_ALARM,
    GAS_ALARM1: GAS_ALARM1,
    BED_HEAD: BED_HEAD,
    BED_HEAD1: BED_HEAD1,
    BED_HEAD2: BED_HEAD2,
    BED_HEAD3: BED_HEAD3,
    BED_HEAD4: BED_HEAD4,
    SERVICE1: SERVICE1,
    SERVICE2: SERVICE2,
    SERVICE3: SERVICE3,
    SERVICE4: SERVICE4,
    SERVICE5: SERVICE5,
    SERVICE6: SERVICE6,
    SERVICE7: SERVICE7,
    SERVICE8: SERVICE8,
    SERVICE11: SERVICE11,
    SERVICE21: SERVICE21,
    SERVICE31: SERVICE31,
    SERVICE41: SERVICE41,
    PROJECT1: PROJECT1,
    PROJECT11: PROJECT11,
    PROJECT12: PROJECT12,
    PROJECT2: PROJECT2,
    PROJECT21: PROJECT21,
    PROJECT22: PROJECT22,
    PROJECT3: PROJECT3,
    PROJECT31: PROJECT31,
    PROJECT32: PROJECT32,
    PROJECT4: PROJECT4,
    PROJECT41: PROJECT41,
    PROJECT42: PROJECT42,
    PROJECT5: PROJECT5,
    PROJECT51: PROJECT51,
    PROJECT52: PROJECT52,
    PROJECT6: PROJECT6,
    PROJECT61: PROJECT61,
    PROJECT62: PROJECT62,
    PROJECT7: PROJECT7,
    PROJECT71: PROJECT71,
    PROJECT72: PROJECT72,
    PROJECT8: PROJECT8,
    PROJECT81: PROJECT81,
    PROJECT82: PROJECT82,
    PROJECT9: PROJECT9,
    PROJECT91: PROJECT91,
    PROJECT92: PROJECT92,
    PROJECT10: PROJECT10,
    PROJECT101: PROJECT101,
    PROJECT102: PROJECT102,
    PROJECT011: PROJECT011,
    PROJECT0111: PROJECT0111,
    PROJECT0112: PROJECT0112,
    PROJECT012: PROJECT012,
    PROJECT0121: PROJECT0121,
    PROJECT0122: PROJECT0122,
    PROJECT013: PROJECT013,
    PROJECT0131: PROJECT0131,
    PROJECT0132: PROJECT0132,
    PROJECT014: PROJECT014,
    PROJECT0141: PROJECT0141,
    PROJECT0142: PROJECT0142,
    PROJECT015: PROJECT015,
    PROJECT0151: PROJECT0151,
    PROJECT0152: PROJECT0152,
    PROJECT016: PROJECT016,
    PROJECT0161: PROJECT0161,
    PROJECT0162: PROJECT0162,
    PROJECT017: PROJECT017,
    PROJECT0171: PROJECT0171,
    PROJECT0172: PROJECT0172,
    PROJECT018: PROJECT018,
    PROJECT0181: PROJECT0181,
    PROJECT0182: PROJECT0182,
    PROJECT019: PROJECT019,
    PROJECT0191: PROJECT0191,
    PROJECT0192: PROJECT0192,
    PROJECT020: PROJECT020,
    PROJECT0201: PROJECT0201,
    PROJECT0202: PROJECT0202,
    PROJECT021: PROJECT021,
    PROJECT0211: PROJECT0211,
    PROJECT0212: PROJECT0212,
    PROJECT022: PROJECT022,
    PROJECT0221: PROJECT0221,
    PROJECT0222: PROJECT0222,
    PROJECT023: PROJECT023,
    PROJECT0231: PROJECT0231,
    PROJECT0232: PROJECT0232,
    PROJECT024: PROJECT024,
    PROJECT0241: PROJECT0241,
    PROJECT0242: PROJECT0242,
    PROJECT025: PROJECT025,
    PROJECT0251: PROJECT0251,
    PROJECT0252: PROJECT0252,
    PROJECT026: PROJECT026,
    PROJECT0261: PROJECT0261,
    PROJECT0262: PROJECT0262,
    PROJECT027: PROJECT027,
    PROJECT0271: PROJECT0271,
    PROJECT0272: PROJECT0272,
    PROJECT028: PROJECT028,
    PROJECT0281: PROJECT0281,
    PROJECT0282: PROJECT0282,
    PROJECT029: PROJECT029,
    PROJECT0291: PROJECT0291,
    PROJECT0292: PROJECT0292,
    PROJECT030: PROJECT030,
    PROJECT0301: PROJECT0301,
    PROJECT0302: PROJECT0302,
    PROJECT031: PROJECT031,
    PROJECT0311: PROJECT0311,
    PROJECT0312: PROJECT0312,
    PROJECT032: PROJECT032,
    PROJECT0321: PROJECT0321,
    PROJECT0322: PROJECT0322,
    PROJECT033: PROJECT033,
    PROJECT0331: PROJECT0331,
    PROJECT0332: PROJECT0332,
    PROJECT034: PROJECT034,
    PROJECT0341: PROJECT0341,
    PROJECT0342: PROJECT0342,
    PROJECT035: PROJECT035,
    PROJECT0351: PROJECT0351,
    PROJECT0352: PROJECT0352,
    PROJECT036: PROJECT036,
    PROJECT0361: PROJECT0361,
    PROJECT0362: PROJECT0362,
    COMPANY: COMPANY,
    TEAM: TEAM,
    MISSION: MISSION,
    VISION: VISION,
    HONESTY: HONESTY,
    INNOVATION: INNOVATION,
    COLLABORATION: COLLABORATION,
    MAP: MAP,
    TOOLTIP1: TOOLTIP1,
    TOOLTIP2: TOOLTIP2,
    EMAIL: EMAIL,
    LOCATION: LOCATION,
    CONTACT: CONTACT,
}