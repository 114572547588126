import { LABELS } from "../constants/Labels";
import { ROUTES } from "../constants/Routes";

export const services = [
    {
        id: 1,
        service: LABELS.PRODUCTS,
        icon: false,
        route: ROUTES.PRODUCTS
    },
    {
        id: 2,
        service: LABELS.SERVICES,
        icon: false,
        route: ROUTES.SERVICES
    },
    {
        id: 3,
        service: LABELS.ABOUT_US,
        icon: false,
        route: ROUTES.ABOUT_US
    },
    {
        id: 4,
        service: LABELS.INSTALLED_PROJECTS,
        icon: false,
        route: ROUTES.INSTALLED_PROJECTS
    },
]