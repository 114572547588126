import { FONTS } from "../../constants/Fonts";
import { SIZES } from "../../constants/Sizes";
import { SPACING } from "../../constants/Spacing";
import { COLORS } from '../../constants/Colors';
import { BORDER } from "../../constants/Border";

export const SX_STYLES = {
    container:{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    imageStyle:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: "cover",
        width: '100%',
        height: '100%',
    },
    imageStyle1:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: "cover",
        width: '100%',
        height: '100%',
    },
    companyContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    companyName: {
        ...FONTS.font700,
        fontSize: SIZES._60px,
        color: COLORS.blueD66,
        marginBottom: '-1.5vh',
    },
    extensionName:  {
        ...FONTS.font700,
        fontSize: SIZES._60px,
        color: COLORS.blueD66,
    },
    section: {
        ...FONTS.font300,
        fontSize: SIZES._18px,
        color: COLORS.blueD66,
        marginBottom: '0.5vh'
    },
    section1: {
        ...FONTS.font300,
        fontSize: SIZES._20px,
        marginTop: '1.5vh'
    },
    buttonContact1: { 
        ...FONTS.font400,
        fontSize: SIZES._18px,
        color: COLORS.white,
        backgroundColor: COLORS.blueE7,
        textTransform:'none',
        paddingTop: SPACING._16px,
        paddingBottom: SPACING._16px,
        paddingRight: SPACING._28px,
        paddingLeft: SPACING._28px,
        borderRadius: BORDER._8px,
        marginTop: SPACING._48px
    },
    section2: {
        ...FONTS.font400,
        fontSize: SIZES._16px,
        color: COLORS.gray85,
        marginBottom: SPACING._32px
    },
    logoPartnersContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        paddingLeft: SPACING.x_large,
        paddingRight: SPACING.x_large,
        paddingTop: SPACING._96px,
        paddingBottom: SPACING._96px
    },
    logoRowContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        gap: SPACING._32px
    },
    logoPartnersWrapper: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center', 
        justifyContent: 'center',
        gap: '4.6vw'
    },
    section2Container:{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute', 
        marginLeft: SPACING.x_large,  
        gap: SPACING._12px,
    },
    section2Label: {
        ...FONTS.font500,
        fontSize: SIZES._16px,
        color: COLORS.blueD66
    },
    section2title: {
        ...FONTS.font600,
        fontSize: SIZES._36px,
        color: COLORS.blueD66
    },
    section2body: {
        ...FONTS.font300,
        fontSize: SIZES._20px,
        marginTop: SPACING._8px
    },
    btnReadContainer: {
        marginTop: SPACING._40px
    },
    section2Image: {
        zIndex: -1, 
        width: '100%'
    },
    productsOfferedContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: SPACING._96px,
        paddingBottom: SPACING._64px,
        paddingLeft: SPACING.x_large,
        paddingRight: SPACING.x_large,
    },
    productsOfferedWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    sectionLabel: {
        ...FONTS.font500,
        fontSize: SIZES._36px,
    },
    sectionSubLabel:{
        ...FONTS.font300,
        fontSize: SIZES._20px,
        color: COLORS.gray85
    },
    listProductsContainer: {
        display: 'flex', 
        flexWrap: 'nowrap', 
        overflow: 'hidden',
        scrollBehavior: 'smooth',
        gap: SPACING._32px
    },
    paginationContainer: {
        display: 'flex', 
        marginLeft: SPACING.x_large, 
        gap: SPACING._32px,
        marginTop: SPACING._32px
    },
    pagination: {
        borderColor: COLORS.grayEA, 
        borderRadius: 100,
        paddingTop: SPACING._16px, 
        paddingBottom: SPACING._16px,
        width: '2.8vw',
        minWidth: 0,
    
    },
    paginationArrow: {
        color: COLORS.gray85,
        fontSize: SIZES._24px,
    },
    servicesContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: SPACING._8px,
        paddingTop: SPACING._96px,
        paddingLeft: SPACING.x_large,
        paddingRight: SPACING.x_large,
        paddingBottom: SPACING._160px,
        marginTop: SPACING._96px,
        background: COLORS.lightGrayFB
    },
    servicesSectionContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        gap: SPACING._20px
    },
    hospitalsContainer: {
        display: 'flex', 
        flexDirection: 'column',
        gap: SPACING._48px,
    },
    awardContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        paddingTop: SPACING._96px, 
        paddingRight: SPACING._96px, 
        paddingLeft: SPACING._96px, 
        paddingBottom: SPACING._96px,
    },
    award: {
        ...FONTS.font500,
        fontSize: SIZES._60px,
    },
    awardDescription: {
        ...FONTS.font300,
        fontSize: SIZES._20px,
        color: COLORS.gray85,
        textAlign: 'center', 
        marginTop: SPACING._24px,
        marginBottom: SPACING._96px
    },
}