export const SIZES = {
    _14px: "1.4vh",
    _16px: '1.6vh',
    _18px: '1.8vh',
    _20px: '2vh',
    _24px: '2.4vh',
    _30px: '3vh',
    _36px: '3.6vh',
    _48px: '4.8vh',
    _60px: '6vh',
};