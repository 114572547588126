import { SPACING } from "../../constants/Spacing";
import { FONTS } from "../../constants/Fonts";
import { SIZES } from "../../constants/Sizes";
import { COLORS } from "../../constants/Colors";

export const SX_STYLES = {
    container: {
        paddingTop: SPACING._64px,
        paddingLeft: SPACING.x_large,
        paddingRight: SPACING.x_large,
        paddingBottom: SPACING._96px,
    },
    sectionLabel: { 
        ...FONTS.font500,
        fontSize: SIZES._36px,
        marginBottom: '7.5vh'
    },
    rowContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '7.5vh'
    },
    zoom: {
        position: 'absolute', 
        background: COLORS.transparent, 
        height: '54.2vh', 
        width: '40.5vh',
        zIndex: 10, 
        marginTop: '12.9vh',
        marginLeft: '8.7vh'
    },
    zoomBackdrop: {
        position: 'absolute', 
        background: COLORS.backDrop,
        zIndex: 999, 
        height: '200vh', 
        width: '100%',
        left: 0, 
    },
    zoomCarouselContainer: {
        display: 'flex', 
        justifyContent: 'center' 
    },
    zoomCarouselWrapper: {
        position: 'absolute', 
        zIndex: 999, 
        top: '47%'
    },
    carouselContainer1: {
        width: '55.401vw', 
    },
    carouselContainer: {
        paddingLeft: 0, 
        paddingRight: 0, 
        width: '58.3vh', 
        marginTop: 0
    },
    allPhotosContainer: {
        display: 'flex',
        justifyContent: 'flex-start', 
        gap: SPACING._12px,
        marginTop: SPACING._12px 
    },
    allPhotosStyle: {
        cursor: 'pointer',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: "cover"
    },
    accordionContainer: {
        marginTop: '10vh',
    },
    more: {
        ...FONTS.font500,
        fontSize: SIZES._16px,
        color: COLORS.white,
        marginLeft: '2.5vh'
    },
    boxOpacity: {
        background: '#39393980', 
        width: '12.5vh', 
        height: '12.5vh', 
        marginBottom: '-7vh',
        zIndex: 1
    }
}