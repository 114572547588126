import { Button } from '@mui/material';
import React from 'react';
import { SX_STYLES } from '../../styles/components/cores/RoundedOptionButtonStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const RoundedOptionButton = (props) => {
    return (
        <Button sx={SX_STYLES.navLinkStyles}
            id="fade-button"
            aria-controls={props.aria_controls}
            aria-haspopup="true"
            aria-expanded={props.aria_expand}
            endIcon={props.endIcon ? <KeyboardArrowDownIcon /> : null}
            onClick={props.onClick}
        >
           {props.text}
        </Button>
    );
}

export default RoundedOptionButton;