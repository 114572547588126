import React, { useContext, useEffect, useState } from 'react';
import { SX_STYLES } from '../../styles/pages/ProductsStyles';
import { IMAGES } from '../../constants/Images';
import { Box, MenuItem, Typography } from '@mui/material';
import { services } from '../../helper/services';
import ListProduct from '../../components/listItems/ListProduct';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RoundedInput from '../../components/cores/RoundedInput';
import CustomDropdown from '../../components/general/CustomDropdown';
import { productCategory } from '../../helper/category';
import { ArrowDownward } from '@mui/icons-material';
import { ROUTES } from '../../constants/Routes';
import { ServicesContext } from '../../components/provider/ServicesProvider';
import ListBreadCrumbs from '../../components/listItems/ListBreadCrumbs';
import { LABELS } from '../../constants/Labels';
import { useNavigate } from 'react-router-dom';

const Services = (props) => {
    const servicesContext = useContext(ServicesContext);
    const [pageNo, setPageNo] = useState(0);
    const [category, setCategory] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
        var filtered = servicesContext.breadCrumbs.filter(function(el) { return el.crumb !== LABELS.SERVICES }); 
        servicesContext.setBreadCrumbs(filtered);
    }, []);

    const maxPage = Math.ceil(services.length/10);

    //for previous and next icon click
    const onNextPage = () => { 
        setPageNo((pageNo + 1)%maxPage);
    };
    const onPrevPage = () => {
        setPageNo((pageNo + 10 -1)%maxPage);
    };

    //for pagination number click
    const handleChange = (event, value) => {
        setPageNo((value - 1)%maxPage)
    };

    //for category dropdown click
    const handleChangeCategory = (event) => {
        setCategory(event.target.value);
    };

    const goToProductDetails = (item) => {
        let productDetails = {
            productItem: item,
            routeFrom: ROUTES.SERVICES
        }
        servicesContext.setBreadCrumbs(prevArray => [...prevArray, {crumb: LABELS.SERVICES}]);
        servicesContext.setProductData(productDetails);
        navigate(ROUTES.PRODUCT_DETAILS);
    }

    return (
        <Box sx={SX_STYLES.container}>
            <Box sx={{height: '8.8vh'}}/>
            <Box sx={SX_STYLES.productsOfferedContainer}>
                <ListBreadCrumbs data={servicesContext.breadCrumbs} currentCrumb={LABELS.SERVICES}/>
                <Box sx={SX_STYLES.productsOfferedWrapper}>
                    <Box sx={SX_STYLES.servicesSectionContainer}>
                        <Typography sx={SX_STYLES.sectionLabel}>All Services</Typography>
                        <Typography sx={SX_STYLES.sectionSubLabel}>Hear from some of our amazing customers who are automating their finances.</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={SX_STYLES.searchContainer}>
                <RoundedInput image={IMAGES.SEARCH} placeholder='Search'/>
                <CustomDropdown value={category} icon={<ArrowDownward sx={SX_STYLES.icon}/>} onSelect={handleChangeCategory}>
                    {productCategory.map((item, index) => (
                        <MenuItem sx={SX_STYLES.menu} value={item.id} key={index}>
                            {item.category}
                        </MenuItem>
                    ))}
                </CustomDropdown>
            </Box>
            <Box sx={SX_STYLES.listProductsContainer}>
                {
                    services.slice(pageNo*10,10*(pageNo+1)).map((item, index) => (
                            <Box key={index}>
                                <ListProduct 
                                    image={
                                        item.category === 'Service' && index === 0 ? 
                                            IMAGES.SERVICE11 
                                        :   
                                        item.category === 'Service' && index === 1 ? 
                                            IMAGES.SERVICE21 
                                        :     
                                        item.category === 'Service' && index === 2 ? 
                                            IMAGES.SERVICE31 
                                        :     
                                        item.category === 'Service' && index === 3 ? 
                                            IMAGES.SERVICE41 
                                        : 
                                        item.image
                                    } 
                                    title={item.title} 
                                    body={item.body} 
                                    onClick={()=>goToProductDetails(item)} 
                                    bordered 
                                    full
                                />
                            </Box>  
                        )
                    )
                }
            </Box>
            <Box sx={SX_STYLES.paginationContainer}>
                <Box sx={{marginRight: '0vw'}}>
                    <Pagination
                        sx={SX_STYLES.paginationStyle}
                        count={maxPage}
                        onChange={handleChange}
                        page={pageNo + 1}
                        renderItem={(item) => (
                            <PaginationItem
                                sx={SX_STYLES.pageNumber}
                                slots = {
                                            { 
                                                previous: (props) => (
                                                    <Box sx={[SX_STYLES.pagination, {marginRight: '15.35vw'}]}>
                                                        <ArrowBackIcon onClick={onPrevPage} sx={{fontSize: '2.5vh'}}/>
                                                        <Typography sx={SX_STYLES.paginationLabel}>Previous</Typography>
                                                    </Box>
                                                ), 
                                                next: (props) => ( 
                                                    <Box sx={[SX_STYLES.pagination, {marginLeft: '16.55vw'}]}>
                                                        <Typography sx={SX_STYLES.paginationLabel}>Next</Typography>
                                                        <ArrowForwardIcon onClick={onNextPage} sx={{fontSize: '2.5vh'}}/>
                                                    </Box>
                                                )
                                            }
                                        }
                                        {...item}
                            />
                        )}
                    />
                </Box>
            </Box>
        
        </Box>
    );
}

export default Services;