import { Box, TextField } from '@mui/material';
import React from 'react';
import { SX_STYLES } from '../../styles/components/cores/RoundedInputStyles';

const RoundedInput = (props) => {
    return (
        <TextField
            variant='outlined'
            InputProps={{
                startAdornment: (
                    props.icon ? props.icon :
                    <Box component='img' src={props.image} height={'1.8vh'}/>
                )}
            }
            inputProps={SX_STYLES.inputPropsStyle}
            sx={SX_STYLES.inputStyle}
            {...props}
        />
    );
}

export default RoundedInput;