import React from 'react';
import { COLORS } from '../../constants/Colors';
import { FONTS } from '../../constants/Fonts';
import { Link } from 'react-router-dom';
import { SIZES } from '../../constants/Sizes';

const ListLinks = (props) => {
    const linkStyle = {
        ...FONTS.font300,
        fontSize: SIZES._16px,
        textDecoration: "none",
        boxShadow: "none",
        color: props.itemID === 1 ? COLORS.blueD66 : COLORS.blueD4,
        cursor: props.itemID === 1 ? 'unset' : 'pointer',
    }
    
    return (
        <Link style={linkStyle} to={props.to} target={props.to ? "_blank" : null}>
            {props.link}
        </Link>
      
    );
}

export default ListLinks;