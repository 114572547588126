import { COLORS } from "../../../constants/Colors"
import { FONTS } from "../../../constants/Fonts"
import { SIZES } from "../../../constants/Sizes"
import { SPACING } from "../../../constants/Spacing"

export const SX_STYLES = {
    imageStyle: {
        borderRadius: '2vw',
        width: '100%',
        height: '51.6vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: "cover"
    },
    sectionContainer: {
        top: '10.5vh', 
        position: 'absolute'
    },
    sectionWrapper: {
        display: 'flex', 
        flexDirection: 'column', 
        gap: SPACING._12px
    },
    sectionLabel: {
        ...FONTS.font500,
        fontSize: SIZES._16px,
        color: COLORS.blueD66
    },
    sectionTitle: {
        ...FONTS.font600,
        fontSize: SIZES._36px,
        color: COLORS.blueD66,
        whiteSpace: 'pre-line', 
        lineHeight: 1.2
    },   
    sectionBody: {
        ...FONTS.font300,
        fontSize: SIZES._20px,
        whiteSpace: 'pre-line',
        marginTop: SPACING._8px
    },
    btnReadContainer: {
        marginTop: SPACING._40px
    },
    sectionContainer1: {
        display: 'flex', 
        flexDirection:'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        width: '100%', 
        top: '12vh', 
        position: 'absolute'
    }
}