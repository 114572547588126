export const SPACING = {
    x_small: '3vw',
    small: '5vw',
    medium: '7vw',
    large: '9vw',
    x_large: '13vw',
    xx_large: '15vw',

    _6px: '0.6vh',
    _8px: '0.8vh',
    _15px: '1.5vh',
    _20px: '2vh',
    _22px: '2.2vh',
    _24px: '2.4vh',
    _26px: '2.6vh',
    _28px: '2.8vh',
    _30px: '3vh',
    _32px: '3.2vh',
    _40px: '4vh',
    _48px: '4.8vh',
    _64px: '6.4vh',
    _96px: '9.6vh',
    _160px: '16vh',

    //button padding
    _10px: '1vh',
    _12px: '1.2vh',
    _16px: '1.6vh',
    _18px: '1.8vh',
};