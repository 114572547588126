import { Button } from '@mui/material';
import React from 'react';
import { SX_STYLES } from '../../styles/components/cores/RoundedButtonStyles';
import { COLORS } from '../../constants/Colors';

const RoundedButton = (props) => {
    const buttonStyle = {
        fontFamily: props.variant === 'text' ? "Lexend-Medium" : 'Lexend-Regular',
        color: props.variant === 'text' ? COLORS.blueD4 : COLORS.white,
        backgroundColor: props.variant === 'text' ? COLORS.lightBlueFD : COLORS.blueE7,
    }
    return (
        <Button variant={props.variant} sx={[SX_STYLES.btnStyle, buttonStyle]} {...props}>{props.text}</Button>
    );
}

export default RoundedButton;