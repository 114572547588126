import { Box, Button, ListItem, Typography } from '@mui/material';
import React from 'react';
import { SX_STYLES } from '../../styles/components/listItems/ListProductStyles';
import { SPACING } from '../../constants/Spacing';

const ListProduct = (props) => {
    const edgeMargin = {
        marginLeft: props.index === 0 ? SPACING.x_large : 0,
        marginRight: props.index === props.arrayLength - 1 ? SPACING.x_large : 0,
        width: props.medium ? '39.2vh' : '100%'
    }
    const edgeMarginDescription = {
        width: props.medium ? null : '94%'
    }
    const imageCover = {
        width: props.small ? '53%' : props.medium ? '70%' : '100%',
        borderTopLeftRadius: props.medium || props.small ? 0 : '1.4vh', 
        borderTopRightRadius: props.medium || props.small ? 0 : '1.4vh', 
    }
    const textWrap = {
        width: props.medium ? '34.5vh' : '32vw',
    }
    return (
        <ListItem sx={[SX_STYLES.container, edgeMargin]}>
            <Box sx={SX_STYLES.containerWrapper}>
                <Box component='img' src={props.image} sx={[SX_STYLES.imageStyle, imageCover]}/>
            </Box>
            <Box sx={[SX_STYLES.containerDescription, edgeMarginDescription]}>
                <Typography sx={[SX_STYLES.title, textWrap]}>{props.title}</Typography>
                <Typography sx={[SX_STYLES.body, textWrap]}>{props.body}</Typography>
                <Box sx={SX_STYLES.buttonContainer}>
                    <Button variant='text' sx={SX_STYLES.button} onClick={props.onClick}>Read more</Button>
                </Box>
            </Box>
            
        </ListItem>
    );
}

export default ListProduct;