import { IMAGES } from "../constants/Images"

export const products = [
    {
        id: 1,
        image: IMAGES.OXYGEN_CONCENTRATORS,
        title: 'Oxysonicz Oxygen Generator',
        body: 'The unique OXYSONICZ Modular PSA Medical Oxygen Concentrators from HUNDREDFOLDS apply the Pressure Swing Adsorption (PSA) process for the onsite production of medical oxygen from clean compressed air confirm the Oxygen 93% Monograph of the European and US Pharmacopoeia.',
        photos: [
            {
                id: 1,
                image: IMAGES.OXYGEN_CONCENTRATORS,
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description:   [
                    {
                        sectionDescription: 'The unique OXYSONICZ Modular PSA Medical Oxygen Concentrators from HUNDREDFOLDS apply the Pressure Swing Adsorption (PSA) process for the onsite production of medical oxygen from clean compressed air confirm the Oxygen 93% Monograph of the European and US Pharmacopoeia.',
                        subContent: []
                    },
                    {
                        sectionDescription: 'You can increase the capacity of your OXYSONICZ Modular PSA Medical Oxygen Concentrator at any time simply by adding PSA modules or by installing a so-called Dual-Bank in parallel without additional modifications.',
                        subContent: []
                    }
                ],         
            },
            {
                accordionLabel: 'Standard Components',
                description: [
                    {
                        sectionDescription: '',
                        subContent: [
                            {
                                sectionLabel: '',
                                subDescription: 'Feed Air Filters'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'PSA Adsorber Vessel Module(s) in anodised Aluminum'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Process Valves, Regulators and Piping in Stainless Steel and compatible for Medical Oxygen'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Exhaust Silencers in Stainless Steel'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Control System with Siemens PLC for fully automatic operation of the OXYSONICZ concentrator, including EherNet/IP Connection for remote monitoring & control'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Touch Panel based Human Machine Interface (HMI) with System Diagnostics'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Onboard Oxygen Analyser with Zirconium Oxide Sensor'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Onboard Oxygen Discharge Pressure Transmitter'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'System for Automatic Venting of Off-Spec Oxygen'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Set of Oxygen Bacterial Filters'
                            }
                        ]
                    }
                ]
            },
            {
                accordionLabel: 'Features',
                description: [
                    {
                        sectionDescription: '',
                        subContent: [
                            {
                                sectionLabel: 'Convenient',
                                subDescription: 'systems operate automatically, in response to demand in the downstream distribution system. It eliminates high-cost labor, supplier dependence, downtime caused by supplier’s late deliveries, and unexpected empties and loss of expensive cylinders.',
                            },
                            {
                                sectionLabel: 'Economical',
                                subDescription: 'If two cylinders (244 CF/7M3) is consumed weekly, the Oxysonicz on-site generator offers significant advantages. Its proven PSA technology cuts rental, labor, and transportation costs, along with bulk user evaporation losses and liabilities. Oxysonicz ensures swift ROI and consistent, stable gas costs year after year.',
                            },
                            {
                                sectionLabel: 'Safe',
                                subDescription: 'Has the ability to implement systems which require continuous duty 24 hour-per-day allows the company years of trouble-free operations. Low operating pressures associated with these systems increase safety and give added security in the workplace, eliminating the need for hazardous storage.',
                            }
                        ]
                    }
                ]
            },
        ],
    },
    {
        id: 2,
        image: IMAGES.GAS_MONITORING,
        title: 'Medical Gas Monitoring System',
        body: 'OXYSONICZ Medical gas monitoring systems are designed to monitor and control the distribution of medical gases throughout a healthcare facility. It ensure that the gases are delivered at the correct pressure, flow rate, and purity.',
        photos: [
            {
                id: 1,
                image: IMAGES.GAS_MONITORING
            },
            {
                id: 2,
                image: IMAGES.GAS_MONITORING1
            },
            {
                id: 3,
                image: IMAGES.GAS_MONITORING2
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description:   [
                    {
                        sectionDescription: 'OXYSONICZ Medical gas monitoring systems are designed to monitor and control the distribution of medical gases throughout a healthcare facility. It ensure that the gases are delivered at the correct pressure, flow rate, and purity.',
                        subContent: []
                    },
                    {
                        sectionDescription: 'These systems typically consist of various components, including gas sensors, pressure regulators, alarms, control panels, and monitoring software. Gas sensors detect gas levels and pressure, while alarms alert healthcare staff to any deviations from the specified parameters.',
                        subContent: []
                    }
                ],         
            },
            {
                accordionLabel: 'Displays',
                description: [
                    {     
                        sectionDescription: '',
                        subContent: [
                            {
                                sectionLabel: '',
                                subDescription: 'Gas Pressure'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Gas Purity'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Flow Rate'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Total Flow'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Data Logger'
                            },
                        ]
                    },
                ],         
            },
            {
                accordionLabel: 'Other Components',
                description:   [
                    {
                        sectionDescription: 'These systems typically consist of various components, including gas sensors, pressure regulators, alarms, control panels, and monitoring software.',
                        subContent: []
                    },
                    {
                        sectionDescription: 'Gas sensors detect gas levels and pressure, while alarms alert healthcare staff to any deviations from the specified parameters.',
                        subContent: []
                    }
                ],      
            },
        ],
    },
    {
        id: 3,
        image: IMAGES.AUTOMATIC_MANIFOLD,
        title: 'Oxygen Automatic Manifold System Digital Touchscreen Display Type',
        body: 'Oxygen automatic manifolds are mainly suitable for continuous consumption of bottled oxygen in hospitals. They offer a number of advantages over traditional manual manifolds, including:',
        photos: [
            {
                id: 1,
                image: IMAGES.AUTOMATIC_MANIFOLD
            },
            {
                id: 2,
                image: IMAGES.AUTOMATIC_MANIFOLD1
            },
            {
                id: 3,
                image: IMAGES.AUTOMATIC_MANIFOLD2
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'Oxygen automatic manifolds are mainly suitable for continuous consumption of bottled oxygen in hospitals. They offer a number of advantages over traditional manual manifolds, including:',
                        subContent: [
                            {
                                sectionLabel: 'Continuous supply of oxygen',
                                subDescription: 'Oxygen automatic manifolds can automatically switch to a new cylinder when the current cylinder becomes empty, ensuring a continuous supply of oxygen to patients.'
                            },
                            {
                                sectionLabel: 'Reduced workload for staff',
                                subDescription: 'Oxygen automatic manifolds eliminate the need for staff to manually switch cylinders, freeing them up to focus on other tasks.'
                            },
                            {
                                sectionLabel: 'Improved safety',
                                subDescription: 'Oxygen automatic manifolds reduce the risk of human error, such as accidentally failing to switch cylinders in time.'
                            },
                        ]
                    }
                ]
            },
            {
                accordionLabel: 'Specification',
                description: [
                    {
                        sectionDescription: '',
                        subContent: [
                            {
                                sectionLabel: '',
                                subDescription: 'Safe valve open pressure: 1.25 MpaOxygen automatic manifolds can automatically switch to a new cylinder when the current cylinder becomes empty, ensuring a continuous supply of oxygen to patients.Safe valve open pressure: 1.25 Mpa'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Available for Oxygen, Nitrous, CO2'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Stainless steel diaphragm of reducer, better to control flow'
                            },
                            {
                                sectionLabel: '',
                                subDescription: '100% testing before release'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Open design to meet requirements of expansion in future'
                            },
                            {
                                sectionLabel: '',
                                subDescription: '100% testing before release'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'A/C: 220 V'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Input : 0.4-15 Mpa Output: 50-200 m3/gh, 0.4-4.5 Mpa'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'With alarm system and remote alarming'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Input / output signal: 4-20mA, 485 Bus communication'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'With LCD Display, Closed box without interference'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Production size: 600*500*265 mm'
                            },
                        ]
                    }
                ]         
            },
            {
                accordionLabel: 'Features and Benefits',
                description: [
                    {
                        sectionDescription: '',
                        subContent: [
                            {
                                sectionLabel: '',
                                subDescription: 'Continuous supply and smooth automatic bank switch-over, without pressure fluctuation.'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Maintenance friendly designed to enable maintenance during operation without the need to disrupt the gas supply.'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'LCD 7" touch screen display.'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Graphically & Digitally display the actual status of each source.'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Visual and Audio Alarm signals in case of change between banks, “Below minimum” and High/Low line supply pressure.'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Leak detection from manifold on Stand-by mode.'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Event logging, recording all alarms and panel activities.'
                            },
                        ]
                    }
                ]    
            },
            {
                accordionLabel: 'Measurements',
                description: [
                    {
                        sectionDescription: '',
                        subContent: [
                            {
                                sectionLabel: '',
                                subDescription: 'Zirconia O2 Sensor: 0-96% 02'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Infrared CO2 Sensor: 0-1000ppm'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Electrochemical CO Sensor: 0-50ppm'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Optional Paramagnetic Sensor 0-100%'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Optional moisture transmitter available'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Measures up to four parameters simultaneously'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Large LCD Display Touchscreen 7"'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Optional built-in audible and visual alarms'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Programmable O2, CO and CO2 alarms output'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Output - 4-20mA per channel. One common fault relay.'
                            },
                        ]       
                    }
                ]
                 
            },
            {
                accordionLabel: 'Parameters',
                description: [
                    {
                        sectionDescription: '',
                        subContent: [
                            {
                                sectionLabel: '',
                                subDescription: '100-240 VAC/50-60 Hz power supply Enclosure'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Painted mild steel'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Visual indication'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'LCD touchscreen display Alarm levels'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Output-4-20mA per channel One common fault relay'
                            },
                            {
                                sectionLabel: '',
                                subDescription: '2 Alarm Contacts per Channel Sample Gas outlet'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Positive pressure samples up to 10 Barg. Regulated with enclosure.'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Sample connection'
                            },
                            {
                                sectionLabel: '',
                                subDescription: '6mm/1/4"'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Electrical connections'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Two-off M20 gland entry holes'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Temperature range Overall operating: 20 °C to +50°C Environmental rating IP65'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Humidity range'
                            },
                            {
                                sectionLabel: '',
                                subDescription: '15% to 95% relative, non-condensing'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Electromagnetic compatibility'
                            },
                        ]       
                    }
                ]            
            },
        ],
    },
    {
        id: 4,
        image: IMAGES.GAS_ALARM,
        title: 'Medical Gas Alarm System With Digital Flow Meter & Totalizer',
        body: 'OXYSONICZ Digital display type alarm system for a medical gas pipeline is a wall-mounted unit that monitors the pressure of up to six gases and sounds an alarm if the pressure falls outside of a pre-set range. The system is connected to a printed circuit board located on the rough-in assembly, which is the initial stage of installation of the medical gas pipeline system. Each add-on module can be programmed to provide specific information, such as the gas pressure, the gas temperature, and the flow rate of the gas.',
        photos: [
            {
                id: 1,
                image: IMAGES.GAS_ALARM
            },
            {
                id: 2,
                image: IMAGES.GAS_ALARM1
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'OXYSONICZ Digital display type alarm system for a medical gas pipeline is a wall-mounted unit that monitors the pressure of up to six gases and sounds an alarm if the pressure falls outside of a pre-set range. The system is connected to a printed circuit board located on the rough-in assembly, which is the initial stage of installation of the medical gas pipeline system. Each add-on module can be programmed to provide specific information, such as the gas pressure, the gas temperature, and the flow rate of the gas.',
                        subContent: []
                    },
                    {
                        sectionDescription: 'This type of alarm system is important for medical facilities because it ensures that a continuous supply of medical gas is always available to patients. Medical gases, such as oxygen, nitrous oxide, and nitrogen, are essential for many medical procedures and treatments. If the supply of medical gas is interrupted, it can have serious consequences for patients.',
                        subContent: []
                    },
                    {
                        sectionDescription: 'OXYSONICZ Digital display type alarm systems offer a number of advantages over traditional analog alarm systems. They are more accurate and reliable, and they can provide more detailed information about the gas supply. Additionally, digital display type alarm systems are easier to use and maintain.',
                        subContent: []
                    }
                ]
            },
            {
                accordionLabel: 'Benefits',
                description: [
                    {
                        sectionDescription: 'Here are some of the key benefits of using a digital display type alarm system for a medical gas pipeline: ',
                        subContent: [
                            {
                                sectionLabel: 'Accuracy and reliability',
                                subDescription: 'Digital display type alarm systems are more accurate and reliable than traditional analog alarm systems. This is because they use digital sensors to measure the gas pressure, temperature, and flow rate.'
                            },
                            {
                                sectionLabel: 'Detailed information',
                                subDescription: 'Detailed information: Digital display type alarm systems can provide more detailed information about the gas supply than traditional analog alarm systems. For example, they can display the gas pressure in real time, and they can track the cumulative flow of the gas over time.'
                            },
                            {
                                sectionLabel: 'Ease of use and maintenance',
                                subDescription: 'Digital display type alarm systems are easier to use and maintain than traditional analog alarm systems. They are typically equipped with a user-friendly interface, and they can be easily calibrated and repaired.'
                            }
                        ]
                    },
                ]            
            },
            {
                accordionLabel: 'Features',
                description: [
                    {
                        sectionDescription: '',
                        subContent: [
                            {
                                sectionLabel: '',
                                subDescription: '7" LCD Touchscreen Display'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Programmable solid red of flashing indicator'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Audible and digital visual alarm'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Easily tested'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Easily installed'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Oxygen Flow meter w/ Totalizer'
                            }
                        ]
                    },
                    {
                        sectionDescription: 'Master + Combination Alarms monitor source equipment such as:',
                        subContent: [
                            {
                                sectionLabel: '',
                                subDescription: 'Air Compressors'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Vacuum Pumps'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'High/Low Pressure Switches'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Manifolds'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Other pipeline devices'
                            }
                        ]
                    },
                ]                      
              },
            {
                accordionLabel: 'Components',
                description: [
                    {
                        sectionDescription: 'Gas sensors, pressure regulators, alarms, control panels, and monitoring software. Gas sensors detect gas levels and pressure, while alarms alert healthcare staff to any deviations from the specified parameters.',
                        subContent: [
                            {
                                sectionLabel: 'Digital Alarm (Latest Technology)',
                                subDescription: 'Monitors the flow of medical gases through a system of microprocessors capable of detecting irregularities of low or high pressure, according to the established parameters. Have the option to change the pressure parameters, i.e. can read different pressure units (Psi, Kpa and Bar). All of this is indicated on an integrated monitor display.'
                            },
                        ]
                    },
                ]            
            },
        ],
    },
    {
        id: 5,
        image: IMAGES.BED_HEAD,
        title: 'Bed Head Unit',
        body: 'It’s a intensive care system that equips electric power, medical oxygen, medical air, medical vacuum, nurse call and oxygen flow. Provides a variety of features and functions to support patient care and comfort.',
        photos: [
            {
                id: 1,
                image: IMAGES.BED_HEAD
            },
            {
                id: 2,
                image: IMAGES.BED_HEAD1
            },
            {
                id: 3,
                image: IMAGES.BED_HEAD2
            },
            {
                id: 4,
                image: IMAGES.BED_HEAD3
            },
            {
                id: 5,
                image: IMAGES.BED_HEAD4
            },
        ],
        details: [
            {
                accordionLabel: 'Description',
                description: [
                    {
                        sectionDescription: 'It’s a intensive care system that equips electric power, medical oxygen, medical air, medical vacuum, nurse call and oxygen flow. Provides a variety of features and functions to support patient care and comfort.',
                        subContent: []
                    },
                    {
                        sectionDescription: 'This system fix and installs on wall side in a general ward, ICU, recovery room, emergency room, private room and manage patients easily so that also offers more safe and efficient care circumstance to patients and medical staff.',
                        subContent: []
                    },
                ]     
            },
            {
                accordionLabel: 'Features',
                description: [
                    {
                        sectionDescription: '',
                        subContent: [
                            {
                                sectionLabel: '',
                                subDescription: 'Built-in lighting to provide illumination for the patient and medical staff.'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Nurse call (optional) includes nurse call button for patients to request assistance.'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Communication Features (optional) intercom system to allow patients to communicate to nurse station.'
                            },
                        ]
                    },
                    {
                        sectionDescription: 'Oxygen Flow meter',
                        subContent: [
                            {
                                sectionLabel: '',
                                subDescription: 'Display oxygen flow and consumption.'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'Measuring the Flow, Pressure, and Total Flow simultaneously.'
                            },
                            {
                                sectionLabel: '',
                                subDescription: 'With LED Light Display.'
                            },
                        ]
                    },
                ]    
            },
        ],
    },
]