import { Select } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { SX_STYLES } from "../../styles/components/general/CustomDropdownStyles";
import { ChevronRight } from "@mui/icons-material";

const CustomDropdown = (props) => {
  return (
    <Box sx={SX_STYLES.selectContainer}>
        <Select
            {...props}
            startAdornment={
              props.icon
            }
           IconComponent={(props) => <ChevronRight sx={SX_STYLES.icon}/>}
            sx={SX_STYLES.selectStyle}
            onChange={props.onSelect}
            displayEmpty
            >
            {props.children}
        </Select>
    </Box>
  );
};

export default CustomDropdown;