import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef } from 'react';
import { SX_STYLES } from '../styles/pages/AboutUsStyles';
import { IMAGES } from '../constants/Images'
import { SPACING } from '../constants/Spacing';
import { ServicesContext } from '../components/provider/ServicesProvider';
import Lottie from "lottie-react";
import groovyWalkAnimation from "./../assets/lottie/location.json";

const AboutUs = (props) => {
    const servicesContext = useContext(ServicesContext);
    const myRef = useRef(null)
    
    useEffect(() => {
        if(servicesContext.isClickedContact){
            // myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            myRef.current.scrollIntoView({behavior:"instant", block: "center"});
        }else{
            window.scrollTo({top: 0, left: 0, behavior: 'instant'});
        }
    }, [servicesContext.isClickedContact]);

    return (
        <>
            <Box sx={SX_STYLES.container}>
                <Box sx={{height: '8.8vh'}}/>
                <Box sx={SX_STYLES.sectionContainer}>
                    <Box sx={SX_STYLES.sectionWrapper}>
                        <Typography sx={SX_STYLES.sectionLabel}>About our company</Typography>
                        <Typography sx={SX_STYLES.sectionSubLabel}>Learn more about the company and the team behind it.</Typography>
                    </Box>
                    <Box component='div' sx={[SX_STYLES.imageStyle, { backgroundImage: `url(${IMAGES.COMPANY})` }]}>
                        <Box sx={SX_STYLES.companyInstallContainer}>
                            <Typography sx={SX_STYLES.installNo}>30+</Typography>
                            <Typography sx={SX_STYLES.projectInstalledText}>Projects Installed</Typography>
                            <Typography sx={SX_STYLES.successfulText}>{'We successfully installed projects\nfrom Luzon, Visayas, to Mindanao.'}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={SX_STYLES.sectionContainer1}>
                    <Box sx={SX_STYLES.sectionWrapper1}>
                        <Typography sx={SX_STYLES.sectionLabel}>We’re just getting started</Typography>
                        <Typography sx={SX_STYLES.sectionSubLabel}>We excel in providing trusted services and delivering quality products.</Typography>
                    </Box>
                    <Box sx={SX_STYLES.rowContainer}>
                        <Box sx={[SX_STYLES.rowContainerWrapper, { width: '47.9%' }]}>
                            <Typography sx={SX_STYLES.description}>{`The company founders have 15 years accumulative experience in marketing various industrial products in the Philippines. Its service policy has always been to have the local technical service personnel all trained at manufacturer's sites to provide competent local service support to existing customer base.\n\n\nIn February 2003, the Hundredfolds Industries Corp. was officially registered at the Securities and Exchange Commission (SEC) and with the Philippines Government Electronic Procurement System (PhilGEPS) in June 2006.`}</Typography>
                        </Box>
                        <Box sx={[SX_STYLES.rowContainerWrapper, { width: '47.9%' }]}>
                            <Typography sx={SX_STYLES.description}>{`At present, Hundrefolds caters to various industrial and medical Institutions in the Philippines. It focuses on medical oxygen generator, medical gas distribution system and its accessories, nitrogen, and oxygen generator, for both industrial applications.\n\n\nHundrefolds also promotes industrial products such as air compressor, compressed air purification equipment and its service, vacuum pumps, mechanical seals, instrumentation equipment and after sales service support.`}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={SX_STYLES.sectionContainer}>
                    <Box sx={SX_STYLES.sectionWrapper}>
                        <Typography sx={SX_STYLES.sectionLabel}>Meet our team!</Typography>
                        <Typography sx={SX_STYLES.sectionSubLabel}>Dedicated professionals committed to excellence in service and quality.</Typography>
                    </Box>
                    <Box component='div' sx={[SX_STYLES.imageStyle, { backgroundImage: `url(${IMAGES.TEAM})` }]}/>
                </Box>
                <Box sx={SX_STYLES.sectionContainer1}>
                    <Box sx={SX_STYLES.rowContainer1}>
                        <Box sx={SX_STYLES.rowContainerWrapper}>
                            <Typography sx={SX_STYLES.sectionLabel}>Our Mission</Typography>
                            <Typography sx={SX_STYLES.description}>Hundredfolds trust that using innovative and efficient equipment means greater savings for medical and industrial institutions. Thus, in order to help medical and industrial institutions to accumulate bigger revenue, Hundredfolds will source out and introduce sate of the art equipment. Also, technical assistance will be provided to maximize the optimum capacity of the equipment.</Typography>
                        </Box>
                        <Box sx={[SX_STYLES.rowContainerWrapper, { marginLeft: SPACING._64px}]}>
                            <Box component='div' sx={[SX_STYLES.imageStyle, { backgroundImage: `url(${IMAGES.MISSION})`, height: '56vh', width: '56vh' }]}/>
                        </Box>
                    </Box>
                </Box>
                <Box sx={SX_STYLES.sectionContainer1}>
                    <Box sx={SX_STYLES.rowContainer1}>
                        <Box sx={SX_STYLES.rowContainerWrapper}>
                            <Box component='div' sx={[SX_STYLES.imageStyle, { backgroundImage: `url(${IMAGES.VISION})`, height: '56vh', width: '56vh' }]}/>
                        </Box>
                        <Box sx={[SX_STYLES.rowContainerWrapper, { marginLeft: SPACING._64px}]}>
                            <Typography sx={SX_STYLES.sectionLabel}>Our Vision</Typography>
                            <Typography sx={SX_STYLES.description}>To become the lead supplier and system integrator and efficient medical and industrial systems/products and technical services in Southeast Asia, and to continuously strive for excellence in the products and services provided to all customers.</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={SX_STYLES.sectionContainer1}>
                    <Box sx={SX_STYLES.sectionWrapper1}>
                        <Typography sx={SX_STYLES.sectionLabel}>Our Core Values</Typography>
                        <Typography sx={SX_STYLES.sectionSubLabel}>Our guiding principles for delivering top-notch quality and service.</Typography>
                    </Box>
                    <Box sx={[SX_STYLES.rowContainer, {justifyContent: 'center'}]}>
                        <Box sx={SX_STYLES.coreContainer}>
                            <Box sx={SX_STYLES.coreIconContainer}>
                                <Box component='img' src={IMAGES.HONESTY} height={'2.4vh'}/>
                            </Box>
                            <Typography sx={SX_STYLES.coreTitle}>Honesty</Typography>
                            <Typography sx={SX_STYLES.coreBody}>We will demonstrate honesty in all our business dealings, ensuring that rules are followed & upheld at all times.</Typography>
                        </Box>
                        <Box sx={SX_STYLES.coreContainer}>
                            <Box sx={SX_STYLES.coreIconContainer}>
                                <Box component='img' src={IMAGES.INNOVATION} height={'2.3vh'}/>
                            </Box>
                            <Typography sx={SX_STYLES.coreTitle}>Innovation</Typography>
                            <Typography sx={SX_STYLES.coreBody}>Encourage and welcome innovation, as it does not just produce economic reward, but quickens the heart and make thing better as well.</Typography>
                        </Box>
                        <Box sx={SX_STYLES.coreContainer}>
                            <Box sx={SX_STYLES.coreIconContainer}>
                                <Box component='img' src={IMAGES.COLLABORATION} height={'1.9vh'}/>
                            </Box>
                            <Typography sx={SX_STYLES.coreTitle}>Collaboration</Typography>
                            <Typography sx={SX_STYLES.coreBody}>Practice collaboration as we commit to work towards the attainment of the company’s vision.</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={[SX_STYLES.sectionMapContainer, {marginTop: '-9.6vh'}]}>
                <Box sx={SX_STYLES.sectionWrapper}>
                    <Typography sx={SX_STYLES.sectionLabel}>Check out our branches</Typography>
                    <Typography sx={SX_STYLES.sectionSubLabel}>We have offices that you can visit.</Typography>
                    <Box component='div' height={'12.5vh'} width={'12.5vh'} sx={[SX_STYLES.mapStyle, {backgroundImage: `url(${IMAGES.MAP})`}]}>
                        <Box sx={SX_STYLES.toolTip1}>
                            <Box component={'img'} src={IMAGES.TOOLTIP1} height={'13.5vh'} sx={{marginBottom: '-3vh'}} />
                            <Box height={'7vh'} width={'7vh'}>
                                <Lottie animationData={groovyWalkAnimation} loop={true}/>
                            </Box>
                        </Box>
                        <Box sx={SX_STYLES.toolTip2}>
                            <Box component={'img'} src={IMAGES.TOOLTIP2} height={'13.5vh'} sx={{marginBottom: '-3vh'}}/>
                            <Box height={'7vh'} width={'7vh'}>
                                <Lottie animationData={groovyWalkAnimation} loop={true}/>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box ref={myRef} sx={SX_STYLES.sectionMapContainer1}>
                <Box sx={SX_STYLES.sectionWrapper}>
                    <Typography sx={SX_STYLES.sectionLabel1}>Talk to us!</Typography>
                    <Typography sx={SX_STYLES.sectionSubLabel}>If you have any questions, you can visit our offices or contact us.</Typography>
                    <Box sx={[SX_STYLES.rowContainer, {gap: SPACING._32px, marginTop: SPACING._96px}]}>
                        <Box sx={SX_STYLES.talkToUsContainer}>
                            <Box component={'img'} src={IMAGES.EMAIL} width={'4.8vh'} height={'4.8vh'}/>
                            <Box sx={SX_STYLES.talkToUsWrapper}>
                                <Typography sx={SX_STYLES.talkToUsSectionText}>Email</Typography>
                                <Typography sx={SX_STYLES.talkToUsSectionDesc}>Our ultracare team is here to help.</Typography>
                            </Box>
                            <Typography sx={SX_STYLES.talkToUsSectionContact}>hundredfolds@yahoo.com</Typography>
                        </Box>
                        <Box sx={SX_STYLES.talkToUsContainer}>
                            <Box component={'img'} src={IMAGES.LOCATION} width={'4.8vh'} height={'4.8vh'}/>
                            <Box sx={SX_STYLES.talkToUsWrapper}>
                                <Typography sx={SX_STYLES.talkToUsSectionText}>Main Office</Typography>
                                <Typography sx={SX_STYLES.talkToUsSectionDesc}>Come see as at our office HQ.</Typography>
                            </Box>
                            <Typography sx={SX_STYLES.talkToUsSectionContact}>Sta. Marina Subd, Tigatto, Davao City</Typography>
                        </Box>
                        <Box sx={SX_STYLES.talkToUsContainer}>
                            <Box component={'img'} src={IMAGES.CONTACT} width={'4.8vh'} height={'4.8vh'}/>
                            <Box sx={SX_STYLES.talkToUsWrapper}>
                                <Typography sx={SX_STYLES.talkToUsSectionText}>Phone</Typography>
                                <Typography sx={SX_STYLES.talkToUsSectionDesc}>Available Mon-Fri from 8am to 5pm.</Typography>
                            </Box>
                            <Typography sx={SX_STYLES.talkToUsSectionContact}>{'+63 82 286-2536 / +63 82 228-8560\n+63 2 353-1087'}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default AboutUs;
