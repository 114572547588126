import { COLORS } from "./Colors";
import { SIZES } from "./Sizes";

export const FONTS = {
    font300: {
        fontFamily: "Lexend-Light",
        fontSize: SIZES._14px,
        color: COLORS.black,
    },
    font400: {
        fontFamily: "Lexend-Regular",
        fontSize: SIZES._14px,
        color: COLORS.black,
    },
    font500: {
        fontFamily: "Lexend-Medium",
        fontSize: SIZES._14px,
        color: COLORS.black,
    },
    font600: {
        fontFamily: "Lexend-SemiBold",
        fontSize: SIZES._14px,
        color: COLORS.black,
    },
    font700: {
        fontFamily: "Lexend-Bold",
        fontSize: SIZES._14px,
        color: COLORS.black,
    },
};