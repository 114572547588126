export const COLORS = {
    //primary
    white: "#FFFFFF",
    black: "#101828",
    transparent: "transparent",
    red: "#D05F5A",
    blue: "#1D9BD7",
    blueD66: '#1E2D66',
    blueE7: '#2961E7',
    blueD4: '#214CD4',
    blueF2: '#3F7FF2',
    gray85: "#667085",
    grayDD: "#D0D5DD",
    grayEA: "#EAECF0",
    gray98: '#98A2B3',
    violet: "#6941C6",
    lightGrayFB: "#F9FAFB",
    lightBlueFD: '#DCE9FD',
    lightBlueFF: '#EFF6FF',
    lightBlueA1: '#A1C4FD',
    blur4D: '#FFFFFF4D',
    blur80: '#FFFFFF80',
    backDrop: '#000000D9',
};