import { BORDER } from '../../../constants/Border';
import { COLORS } from '../../../constants/Colors';
import { FONTS } from '../../../constants/Fonts';
import { SIZES } from '../../../constants/Sizes';
import { SPACING } from '../../../constants/Spacing';

export const SX_STYLES = {
    navigationContainer: {
        display: 'flex',
        position: 'fixed',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: '2vh',
        paddingBottom: '2vh',
        width: '100vw',
        backgroundColor: COLORS.lightBlueA1,
        zIndex: 99
    },
    navigationWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: SPACING._32px,
    },
    logoContainer: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center', 
        marginLeft: SPACING.x_large,
        marginRight: SPACING._22px,
        cursor: 'pointer',
        // '@media (max-width: 1439px)' : {
        //     marginLeft: SPACING._64px,
        // },
        // '@media (min-width: 1440px)' : {
        //     marginLeft: SPACING.x_large,
        // },
    },
    logoStyle: {
        height: '4.2vh',
        marginRight: SPACING._6px
    },
    logoName: {
        ...FONTS.font600,
        fontSize: SIZES._16px,
        color: COLORS.blueD66,
    },
    buttonContact: {
        ...FONTS.font500,
        fontSize: SIZES._16px,
        color: COLORS.white,
        backgroundColor: COLORS.blueE7,
        textTransform:'none',
        paddingTop: SPACING._10px,
        paddingBottom: SPACING._10px,
        paddingRight: SPACING._18px,
        paddingLeft: SPACING._18px,
        borderRadius: BORDER._8px,
        marginRight: '14.2vw',
    },
}