import { Box, Typography } from '@mui/material';
import React from 'react';
import { SX_STYLES } from '../../styles/components/listItems/ListHospitalsStyle';

const ListHospitals = (props) => {
    return (
        <Box sx={SX_STYLES.container}>
            <Box sx={SX_STYLES.imageContainer}>
                <Box component='img' src={props.image} sx={SX_STYLES.imageStyle}/>
            </Box>
            <Box sx={SX_STYLES.sectionContainer}>
                <Typography sx={SX_STYLES.date}>{props.date}</Typography>
                <Typography sx={SX_STYLES.hospital}>{props.hospital}</Typography>
                <Typography sx={SX_STYLES.hospitalDescription}>{props.description}</Typography>
            </Box>
        </Box>
    );
}

export default ListHospitals;