import { FONTS } from "../../constants/Fonts";
import { SIZES } from "../../constants/Sizes";
import { SPACING } from "../../constants/Spacing";
import { COLORS } from '../../constants/Colors';
import { BORDER } from "../../constants/Border";

export const SX_STYLES = {
    section2Container:{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute', 
        marginTop: '15vh', 
        marginLeft: SPACING.x_large,
        gap: SPACING._12px
    },
    section2Label: {
        ...FONTS.font500,
        fontSize: SIZES._16px,
        color: COLORS.blueD66
    },
    section2title: {
        ...FONTS.font600,
        fontSize: SIZES._36px,
        color: COLORS.blueD66
    },
    section2body: {
        ...FONTS.font300,
        fontSize: SIZES._20px,
        marginTop: SPACING._8px
    },
    btnReadContainer: {
        marginTop: SPACING._40px
    },
    section2Image: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: "cover",
        width: '100%',
        height: '100%',
    },
    productsOfferedContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: SPACING._64px,
        paddingBottom: SPACING._64px,
        paddingLeft: SPACING.x_large,
        paddingRight: SPACING.x_large,
    },
    productsOfferedWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    servicesSectionContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        gap: SPACING._20px
    },
    sectionLabel: {
        ...FONTS.font500,
        fontSize: SIZES._36px,
    },
    sectionSubLabel:{
        ...FONTS.font300,
        fontSize: SIZES._20px,
        color: COLORS.gray85
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: SPACING.x_large,
        marginRight: SPACING.x_large,
        marginBottom: SPACING._64px
    },
    menu: {
        ...FONTS.font500,
        fontSize: SIZES._14px,
        color: COLORS.blueD66,
    },
    icon: {
        height: '2.6vh', 
        color: COLORS.gray85,
        marginRight: SPACING._10px
    },
    listProductsContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        paddingLeft: SPACING.x_large,
        paddingRight: SPACING.x_large,
        gap: SPACING._32px
    },
    paginationContainer: {
        display: 'flex', 
        justifyContent: 'center',
        marginLeft: SPACING.x_large,
        marginRight: SPACING.x_large,
        marginTop: SPACING._64px,
        marginBottom: SPACING._96px,
    },
    paginationStyle: {
        '.MuiPaginationItem-root': {
            '&.Mui-selected': {
                backgroundColor: COLORS.lightBlueFF,
            },
            '&.MuiPaginationItem-previousNext': {
                borderRadius: 0,
                height: 0,
                padding: 0,
            },
            '&.MuiPaginationItem-page': {
                paddingLeft: '2vh',
                paddingRight: '2vh',
                paddingTop: '2.3vh',
                paddingBottom: '2.3vh',
                borderRadius: 100
            }
        }
    },
    pagination: {
        display: 'flex',
        alignItems: 'center',
        gap: SPACING._12px,
        borderRadius: BORDER._8px,
        border: 1,
        borderColor: COLORS.grayDD,
        paddingLeft: '0.9vh',
        paddingRight: '0.9vh',
        paddingTop: '0.5vh',
        paddingBottom: '0.4vh',
    },
    paginationLabel: {
        ...FONTS.font400,
        fontSize: SIZES._14px,
    },
    pageNumber: {
        ...FONTS.font500,
        fontSize: SIZES._14px,
        color: COLORS.blueD66,
    },
}