import { COLORS } from "../../../constants/Colors";
import { SIZES } from "../../../constants/Sizes";
import { SPACING } from "../../../constants/Spacing";

export const SX_STYLES = {
    container: {
        marginTop: '-9.5vh', 
        paddingLeft: SPACING.x_large, 
        paddingRight: SPACING.x_large,
    },
    navButtonsPropsStyle: {
        style: {
            background: COLORS.grayEA, 
            color: COLORS.gray85
        }
    },
    indicatorIconButtonProps: {
        style: {
            paddingLeft: '0.35vh',
            paddingRight: '0.35vh',
        }
    },
    indicatorContainerProps: {
        style: {
            position: 'absolute', 
            bottom: '5.5vh', 
            zIndex: 1,
        }
    },
    icon: {
        fontSize: SIZES._24px, 
        padding: 0.5
    }
}
