import { BORDER } from '../../../constants/Border';
import { COLORS } from '../../../constants/Colors';
import { FONTS } from '../../../constants/Fonts';
import { SIZES } from '../../../constants/Sizes';
import { SPACING } from '../../../constants/Spacing';

export const SX_STYLES = {
    container: {
        flex: '0 0 auto', 
        flexDirection: 'column', 
        border: 1,
        borderColor: COLORS.grayDD,
        borderRadius: BORDER._16px,
        padding: 0,
    },
    containerWrapper: {
        display:'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        borderTopLeftRadius: BORDER._16px, 
        borderTopRightRadius: BORDER._16px, 
        backgroundColor: COLORS.lightBlueFF, 
        width: '100%', 
        height: '26.9vh',
    },
    imageStyle:{
        objectFit: 'cover',
        width: '100%', 
        height: '100%',
        borderTopLeftRadius: '1.4vh', 
        borderTopRightRadius: '1.4vh', 
    },
    containerDescription: {
        paddingTop: SPACING._20px,
        paddingBottom: SPACING._20px,
        paddingLeft: SPACING._22px, 
        paddingRight: SPACING._22px,
        display: 'flex', 
        flexDirection: 'column',
        gap: SPACING._8px,
    },
    title: {
        ...FONTS.font600,
        fontSize: SIZES._20px,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    body: {
        ...FONTS.font300,
        fontSize: SIZES._16px,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    buttonContainer:{
        alignSelf: 'flex-end', 
        marginTop: SPACING._8px,
    },
    button: {
        ...FONTS.font400,
        fontSize: SIZES._16px,
        color: COLORS.blueF2,
        textTransform:'none',
    }
}