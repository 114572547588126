import { FONTS } from "../../../constants/Fonts";
import { SIZES } from "../../../constants/Sizes";
import { COLORS } from "../../../constants/Colors";

export const SX_STYLES = {
    accordionStyles: {
        border: 0, 
        borderColor: COLORS.grayEA
    },
    title: {
        ...FONTS.font500,
        fontSize: SIZES._24px,
    },
    body: {
        ...FONTS.font300,
        fontSize: SIZES._18px,
        color: COLORS.gray85,
        whiteSpace: 'pre-line'
    }
}
